import React from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { connect } from "react-redux";
import * as ROUTES from "../constants/routes";
import { graphql } from "react-apollo";
import { PROFILE_USER_SET, ACCOUNT_USER_SET } from "../redux/constants/";
import * as storage from "../storage";
import { CREATE_USER } from "../graphql"

const withAuthorization = (condition) => (Component) => {
  class WithAuthorization extends React.Component {
    constructor(props) {
      super(props);

      this.props.onSetProfileUser(storage.getProfileUser());
      this.props.onSetAccountUser(storage.getAccountUser());
    }

    componentDidMount() {
      this.listener = () => {
        let authUser = storage.getAuthUser();
        if (!condition(authUser)) {
          // redirect to login page when credential not exists
          this.props.history.push(ROUTES.LOGIN);
        } else {
          if (!authUser.is_setup_account) {
            // redirect to setup page when setup is not done yet
            this.props.history.push(ROUTES.SETUP);
          } else {
            // prevent accessing setup page after done setuping
            if (this.props.location.pathname === "/setup") 
              this.props.history.push(ROUTES.DASHBOARD);
          }
          if (this.props.location.pathname === "/login") {
            this.props.history.push(ROUTES.DASHBOARD); 
          }

          storage.setProfileUser(authUser);
          this.props.onSetProfileUser(authUser);
        }
      };
      this.listener();
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      return condition(this.props.authUser) ? (
        <Component {...this.props} />
      ) : null;
    }
  }

  const mapStateToProps = (state) => ({
    authUser: state.sessionState.authUser,
    profileUser: state.sessionState.profileUser,
    accountUser: state.sessionState.accountUser,
  });

  const mapDispatchToProps = (dispatch) => ({
    onSetProfileUser: (profileUser) =>
      dispatch({ type: PROFILE_USER_SET, profileUser }),
    onSetAccountUser: (accountUser) =>
      dispatch({ type: ACCOUNT_USER_SET, accountUser }),
  });

  return compose(
    graphql(CREATE_USER, { name: "createUser" }),
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
  )(WithAuthorization);
};

export default withAuthorization;
