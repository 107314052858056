import React from "react";
import { Link } from "react-router-dom";
import * as ROUTES from "../constants/routes";

const NavAccount = ({ path }) => {
  return (
    <nav className="subnav-wrp">
      <Link
        className={`subnav-item ${path === "/apikeys" ? "is-active" : null}`}
        to={ROUTES.MY_APIKEYS}
      >
        API Keys
      </Link>
      <Link
        className={`subnav-item ${path === "/settings" ? "is-active" : null}`}
        to={ROUTES.MY_SETTINGS}
      >
        Referral
      </Link>
      <Link
        className={`subnav-item ${path === "/settings" ? "is-active" : null}`}
        to={ROUTES.MY_SETTINGS}
      >
        Payment
      </Link>
    </nav>
  );
};

export default NavAccount;
