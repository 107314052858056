import { Button, TextField } from "@material-ui/core";
import { withSnackbar } from "notistack";
import React from "react";
import { compose } from "recompose";
import { withAuthorization, withEmailVerification } from "../../session";

const INITIAL_STATE = {
  error: null,
  errorApiPublicKey: null,
  errorApiPrivateKey: null,
  clientId: "",
  clientSecret: "",
  loading: false,
};

class AddBot extends React.Component {
  constructor() {
    super();
    this.state = { ...INITIAL_STATE };
    this.loading = this.loading.bind(this);
    this.goBack = this.goBack.bind(this); // i think you are missing this
  }

  componentDidMount() {
    this.setState({
      clientId: this.props.client_id,
      clientSecret: this.props.secret_id,
    });
  }

  checkStatusApi = () => {
    let { clientId, clientSecret } = this.state;
    this.setState({
      loading: true,
    });
    this.props.checkExchangeAccess
      .refetch({
        client_id: clientId,
        client_secret: clientSecret,
        exchange: this.props.exchange === "Deribit" ? "Deribit" : "Binance",
      })
      .then(({ data }) => {
        if (!data.check_exchange_access.isVerified) {
          this.setState({
            errorApi: data.check_exchange_access.message,
            loading: false,
          });
          this.props.enqueueSnackbar(data.check_exchange_access.message, {
            variant: "error",
          });
        } else {
          this.updateAccount();
        }
      });
  };

  updateAccount = () => {
    let { clientId, clientSecret } = this.state;
    this.props
      .updateAccountUser({
        variables: {
          id: this.props.bot_id,
          client_id: clientId,
          client_secret: clientSecret,
        },
        optimisticResponse: true,
      })
      .then(({ data }) => {
        this.props.enqueueSnackbar("Edit api keys sucess", {
          variant: "success",
        });
        this.props.toggleModal();
      });
  };

  loading = (status) => {
    this.setState({
      loading: status,
    });
  };

  goBack() {
    this.props.history.goBack();
  }

  async onChangeInput(e) {
    this.setState({ [e.target.id]: e.target.value });
  }

  render() {
    return (
      <>
        {/* <div className="container-app"> */}
        <div className="auth-wrp" style={{ marginBottom: 0 }}>
          <div className="box-default is-center is-narrow">
            <div className="modal-body is-flex">
              <h2 className="title">Edit Keys</h2>
              <p className="desc" style={{ maxWidth: 300 }}>
                (Unmanaged only) Two fields for public and private keys from
                exchange and must have "write" access on all so our bot can
                create and manage subaccounts for them. Once they submit our
                server will verify they are under our referral link + that the
                api keys have the proper permissions.
              </p>
              <form
                className="form-wrp"
                noValidate
                autoComplete="off"
                style={{ marginBottom: 20 }}
              >
                <div className="form-group">
                  <TextField
                    error={this.state.errorApiPublicKey ? true : false}
                    id="clientId"
                    type="text"
                    className="input"
                    disabled={this.state.loading}
                    value={this.state.clientId}
                    label="enter Client Id"
                    onChange={(e) => this.onChangeInput(e)}
                    helperText={this.state.errorApiPublicKey}
                  />
                </div>
                <div className="form-group">
                  <TextField
                    error={this.state.errorApiPrivateKey ? true : false}
                    id="clientSecret"
                    type="text"
                    className="input"
                    disabled={this.state.loading}
                    value={this.state.clientSecret}
                    label="enter Client Secret"
                    onChange={(e) => this.onChangeInput(e)}
                    helperText={this.state.errorApiPrivateKey}
                  />
                </div>
              </form>
              <Button
                disabled={this.state.loading}
                type="submit"
                className="button is-primary btn-submit"
                style={{ marginTop: "auto" }}
                onClick={this.checkStatusApi}
              >
                {this.state.loading ? "SAVING..." : "SAVE CHANGES"}
              </Button>
            </div>
          </div>
        </div>
        {/* </div> */}
      </>
    );
  }
}

const condition = (authUser) => !!authUser;

export default withSnackbar(
  compose(
    withEmailVerification,
    withAuthorization(condition)
  )(withSnackbar(AddBot))
);
