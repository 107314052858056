import React, { useState, useEffect } from "react";
import ARROW_UP from "../../assets/images/arrow-up.png";
import ARROW_DOWN from "../../assets/images/arrow-down.png";
import { compose } from "recompose";
import { withAuthorization, withEmailVerification } from "../../session";

import { graphql } from "react-apollo";
import { GET_USER_BY_EMAIL } from "../../graphql";
import CoinGecko from "coingecko-api";

const coinGeckoClient = new CoinGecko();

const AssetsTemp = (props) => {
  const [isCoinGecko, setCoinGecko] = useState("");
  const [isEror, setEror] = useState("");
  const [Balance] = useState("230.0000");
  // const [PriceCalcuBalance, setPriceBalance] = useState("")

  useEffect(() => {
    GetCoinGecko();
  }, []);

  const GetCoinGecko = () => {
    const params = {
      order: CoinGecko.ORDER.MARKET_CAP_DESC,
    };
    coinGeckoClient.coins
      .markets({ params })
      .then((result) => {
        var data = result.data;
        var value = data.slice(0, 11);
        setCoinGecko(value);
      })
      .catch((err) => {
        setEror(err);
      });
  };

  let price = {
    value: "0,000,000,00",
  };

  return (
    <>
      <header
        style={{ justifyContent: "space-between" }}
        className="header-app-assets"
      >
        <h2 className="title-page">My Assets</h2>
        <h2 className="title-page-right">Total Balance : $ {price.value}</h2>
      </header>
      <div className="content-assets">
        {isCoinGecko != null && Array.isArray(isCoinGecko) ? (
          isCoinGecko.map((coin) => {
            let PriceBalance = coin.current_price;
            let dataBalance = Balance;
            let Price = PriceBalance * dataBalance;
            return (
              <div className="content-assets-data">
                <div className="bg-logo" style={{ backgroundColor: "#13e5ff" }}>
                  <img src={coin.image} alt="" className="logo-coinmarket" />
                </div>
                <div className="path-title">
                  <h4 className="title-assets">{coin.name}</h4>
                  <p style={{ color: "white", fontSize: "13px" }}>
                    ({coin.symbol.toUpperCase()})
                  </p>
                </div>
                <div className="content-data">
                  <p className="text-data-assets">Balance : {Balance}</p>
                  <p className="text-data-assets">Value : $ {Price}</p>
                </div>
                <div className="image-logo">
                  <button
                    className="bg-logo"
                    style={{ backgroundColor: "#ffd700" }}
                  >
                    <img src={ARROW_DOWN} alt="" className="logo" />
                  </button>
                  <button
                    className="bg-logo"
                    style={{ backgroundColor: "#13e5ff" }}
                  >
                    <img src={ARROW_UP} alt="" className="logo" />
                  </button>
                </div>
              </div>
            );
          })
        ) : (
          <>
            <h1 className="title-page">Data Not Found</h1>
          </>
        )}
      </div>
    </>
  );
};

const condition = (authUser) => !!authUser;

export default compose(
  graphql(GET_USER_BY_EMAIL, { name: "getUserByEmail" }),
  withEmailVerification,
  withAuthorization(condition)
)(AssetsTemp);
