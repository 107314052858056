import React, { useState, useEffect } from "react";
import * as storage from "../../storage";
import TopBar from "../../components/topbar";
import { withRouter } from "react-router-dom";
import { withSnackbar } from "notistack";
import { withAuthorization } from "../../session";
import { compose } from "recompose";
import { Button } from "@material-ui/core";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { faCopy, faShareAltSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GET_TOTAL_REFERRAL } from "../../graphql";
import { useQuery } from "@apollo/client";

let authUser = storage.getAuthUser() != null ? storage.getAuthUser() : "";

const Referalls = (props) => {
  const [link, setLink] = useState("xtremex.io/r?=");
  const [copySuccess, setCopySuccess] = useState(false);
  const [share, setShare] = useState(false);
  const [referral, setReferral] = React.useState([]);
  const { data: data_referral, error: error_referral } = useQuery(
    GET_TOTAL_REFERRAL,
    {
      variables: { xID: authUser.id ? authUser.id : "" },
    }
  );

  const getRefferal = React.useCallback(() => {
    if (data_referral !== undefined && !error_referral) {
      setReferral(data_referral.x_users_referrals);
    }
  }, [data_referral, error_referral]);

  useEffect(() => {
    // getStore();
    getRefferal();
    if (authUser && authUser.ref_id) {
      setLink("xtremex.io/r?=" + authUser.ref_id);
    }
  }, [authUser, getRefferal]);

  function shareButton() {
    setShare(!share);
  }

  function copyToClipboard() {
    navigator.clipboard.writeText(link);
    setCopySuccess(true);
    setTimeout(() => setCopySuccess(false), 3000);
  }

  return (
    <>
      <TopBar />
      <div className="container-app">
        <main className="app-main-settings main-plain">
          <header className="header-app">
            <h2 className="title-page">Referrals</h2>
            {/* <NavAccount path={path} /> */}
          </header>

          <div className="content-app">
            <from className="from-group-settings">
              <div className="group-items-settings">
                <div className="path-input">
                  {share ? (
                    <div className="share-group">
                      <EmailShareButton url={link}>
                        <EmailIcon size={40} round={true} />
                      </EmailShareButton>
                      <FacebookShareButton url={link}>
                        <FacebookIcon size={40} round={true} />
                      </FacebookShareButton>
                      <TelegramShareButton url={link}>
                        <TelegramIcon size={40} round={true} />
                      </TelegramShareButton>
                      <TwitterShareButton url={link}>
                        <TwitterIcon size={40} round={true} />
                      </TwitterShareButton>
                      <WhatsappShareButton url={link}>
                        <WhatsappIcon size={40} round={true} />
                      </WhatsappShareButton>
                    </div>
                  ) : null}
                  <div className="group-items-referral">
                    <input
                      className="input-text-copy"
                      disabled={true}
                      type="text"
                      value={link}
                    />
                    <Button
                      className="icon-wrapper-copy button is-primary btn-action share-btn"
                      onClick={copyToClipboard}
                    >
                      <FontAwesomeIcon
                        className="icon"
                        color="grey"
                        icon={faCopy}
                        onClick={copyToClipboard}
                      />
                    </Button>

                    <Button
                      className="icon-wrapper-share button is-primary btn-action share-btn"
                      onClick={shareButton}
                    >
                      <FontAwesomeIcon
                        className="icon"
                        color="white"
                        icon={faShareAltSquare}
                        onClick={shareButton}
                      />
                    </Button>
                  </div>
                  <div className="text-coppied">
                    {copySuccess ? <span className="blink">Copied!</span> : ""}
                  </div>
                  {referral.map((data, item) => (
                    <div className="level-referral" key={item}>
                      <p className="text-intruction">
                        Level 1:{" "}
                        <span className="text-intruction">{data.referrals} </span>
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </from>
          </div>
        </main>
      </div>
    </>
  );
};

const condition = (authUser) => !!authUser;

export default withSnackbar(
  compose(withAuthorization(condition))(withRouter(Referalls))
);
