import { Button, TextField } from "@material-ui/core";
import { withSnackbar } from "notistack";
import React, { useState, useEffect } from "react";
import { compose } from "recompose";
import * as ROUTES from "../../constants/routes";
import { FORGOT_PASS } from "../../graphql";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import { useLazyQuery } from "@apollo/client";

const Forgot = (props) => {
  const [forgotPassXtremex, { data }] = useLazyQuery(FORGOT_PASS);
  const [email, setEmail] = useStateWithCallbackLazy("");
  const [errorEmail, setErrorEmail] = useState("");
  // const [error, setError] = useState("");
  const [isLoading, setLoading] = useState(false);

  const emailCheck = (e) => {
    setEmail(e.target.value.toString().trim(), (emails) => {
      let regxp =
        /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (emails.length < 1) {
        setErrorEmail("Email is required.");
      } else if (!regxp.test(emails)) {
        setErrorEmail("Email format is invalid.");
      } else {
        setErrorEmail("");
        return true;
      }
      return false;
    });
  };

  const load = (status) => {
    setLoading(status);
  };

  const validateEmail = () => {
    let regxp =
      /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email.trim().length < 1) {
      setErrorEmail("Email is required.");
    } else if (!regxp.test(email)) {
      setErrorEmail("Email format is invalid.");
    } else {
      setErrorEmail("");
      return true;
    }
    return false;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateEmail()) {
      return false;
    }
    load(true);
    forgotPassXtremex({
      variables: {
        email: email,
      },
    });
  };

  useEffect(() => {
    if (data) {
      if (data.forgot_pass_xtremex.status === true) {
        setEmail("");
        props.enqueueSnackbar(
          "request password reset success send to your email, please check email",
          {
            variant: "success",
          }
        );
        load(false);
        Go_To();
      } else {
        load(false);
        props.enqueueSnackbar(
          "Your account is not verified, please check email",
          {
            variant: "error",
          }
        );
      }
    }
  });

  const Go_To = () => {
    setTimeout(() => {
      window.location = ROUTES.SETUP;
    }, 3000);
  };

  return (
    <>
      <div className="auth-wrp">
        <div className="box-single">
          <h2 className="title title-forgot">Forgot your password?</h2>
          <p className="description">
            Enter your email address to reset your password. You may need to
            check your spam folder or unblock no-reply@xtremex.io
          </p>

          <div className={`form-section`}>
            <form
              className="auth-form-wrp"
              onSubmit={handleSubmit}
              noValidate
              autoComplete="off"
            >
              <div className="form-group">
                <TextField
                  error={errorEmail ? true : false}
                  id="email"
                  type="email"
                  className="input is-fullwidth"
                  disabled={isLoading}
                  value={email}
                  label="Your email"
                  onChange={emailCheck}
                  helperText={errorEmail}
                />
              </div>
              <Button
                disabled={isLoading}
                type="submit"
                className="button btn-block is-primary btn-login btn-auth-lastchild"
              >
                {isLoading ? "SUBMITING..." : "SUBMIT"}
              </Button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default withSnackbar(compose()(withSnackbar(Forgot)));
