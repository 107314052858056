import React, { useState, useEffect } from "react";
import logo from "../../assets/images/xtremex-logo-150.jpg";
import * as ROUTES from "../../constants/routes";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { compose } from "recompose";
import { withSnackbar } from "notistack";
import { Button, TextField } from "@material-ui/core";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import { KEY_FORGOT_PASS, RESET_PASSWORD } from "../../graphql";
import { useLazyQuery, useMutation } from "@apollo/client";

const queryParams = new URLSearchParams(window.location.search);

const Verify = (props) => {
  const [isKey, setKey] = useState("");
  const [isVerified, setVerified] = useState(false);
  const [newPassword, setNewPassword] = useStateWithCallbackLazy("");
  const [errorNewPassword, setErrorNewPassword] = useState("");
  const [isLoading, setLoading] = useState(false);
  // const [setMessage] = useState("");
  const [isCode] = useState(queryParams.get("code"));
  const [isEmail] = useState(queryParams.get("email"));
  const [isCheckError, setCheckError] = useState("");
  const [getKey, { data: key_forgot }] = useLazyQuery(KEY_FORGOT_PASS);
  const [resetPassword] = useMutation(RESET_PASSWORD);

  const load = (status) => {
    setVerified(status);
  };

  useEffect(() => {
    if (isCode === null) {
      load(true);
      Go_Back();
    } else if (isCode !== null) {
      checkActionCode();
    }
  }, []);

  const Go_Back = () => {
    setTimeout(() => {
      window.location = ROUTES.FORGOT;
    }, 1000);
  };

  const checkActionCode = () => {
    load(true);
    getKey({
      variables: { code: isCode, email: isEmail },
    });
  };

  useEffect(() => {
    if (key_forgot) {
      if (key_forgot.key_forgot_pass_xtremex.status === true) {
        setKey(key_forgot.key_forgot_pass_xtremex.data.key);
        load(false);
      } else {
        setCheckError(key_forgot.key_forgot_pass_xtremex.message);
        load(false);
      }
    }
  });

  const validatePassword = () => {
    if (newPassword.trim().length < 1) {
      setErrorNewPassword("Password is required.");
    } else if (newPassword.trim().length < 8) {
      setErrorNewPassword("Password must be at least 8 characters.");
    } else {
      setErrorNewPassword("");
      return true;
    }
    return false;
  };

  const confirmPasswordReset = () => {
    if (!validatePassword()) {
      return false;
    }
    setLoading(true);
    let reset = {
      code: isCode,
      email: isEmail,
      key: isKey,
      password: newPassword,
    };
    resetPassword({ variables: reset })
      .then(({ data }) => {
        if (data.reset_password_xtremex.status === true) {
          props.enqueueSnackbar(data.reset_password_xtremex.message, {
            variant: "success",
          });
          Go_To();
        } else if (data.reset_password_xtremex.status === false) {
          setLoading(false);
          props.enqueueSnackbar(data.reset_password_xtremex.message, {
            variant: "error",
          });
        }
      })
      .catch((e) => {
        setLoading(false);

        props.enqueueSnackbar(e.message, {
          variant: "error",
        });
      });
  };
  const Go_To = () => {
    setTimeout(() => {
      window.location = ROUTES.LOGIN;
    }, 3000);
  };
  const passwordCheck = (e) => {
    setNewPassword(e.target.value.toString().trim(), (passwords) => {
      if (passwords.trim().length < 1) {
        setErrorNewPassword("Password is required.");
      } else if (passwords.trim().length < 8) {
        setErrorNewPassword("Password must be at least 8 characters.");
      } else {
        setErrorNewPassword("");
        return true;
      }
      return false;
    });
  };

  if (isVerified) {
    return (
      <div className="auth-wrp-outer">
        <div className="auth-wrp-inner">
          <h1 className="is-sr-only">Xtremex</h1>
          <img src={logo} className="auth-logo-title" alt="Xtremex" />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="auth-wrp-outer">
        <div className="auth-wrp-inner">
          <h1 className="is-sr-only">Xtremex</h1>
          <img src={logo} className="auth-logo-title" alt="Xtremex" />
          <div className="auth-body single">
            <div className="auth-wrp">
              {isCheckError ? (
                <div className="box-verify">
                  <FontAwesomeIcon
                    className="icon error"
                    icon={faExclamationCircle}
                  />
                  <h2 className="title">
                    {isCheckError ? isCheckError : null}
                  </h2>
                </div>
              ) : (
                <div className="box-verify">
                  <h2>RESET PASSWORD</h2>
                  <form className="form-api-wrp" noValidate autoComplete="off">
                    <div className="form-group">
                      <TextField
                        error={errorNewPassword ? true : false}
                        id="newPassword"
                        type="password"
                        className="input is-fullwidth"
                        value={newPassword}
                        label="enter New Password"
                        onChange={passwordCheck}
                        helperText={errorNewPassword}
                        style={{ marginBottom: 20 }}
                      />
                    </div>
                  </form>
                  <Button
                    disabled={isLoading}
                    className="button btn-block is-primary"
                    type="submit"
                    onClick={confirmPasswordReset}
                    style={{ marginTop: 20 }}
                  >
                    {isLoading ? "RESET..." : "RESET"}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default compose(withSnackbar)(Verify);
