import { gql } from "@apollo/client";

// Login
export const LOGIN_AUTH = gql`
  query LoginAuth($email: String!, $password: String!) {
    login_xtremex(email: $email, password: $password) {
      status
      message
      data {
        id
        email
        name
        telegram
        is_2fa
        is_setup_account
        ref_id
        ref_by
        token
        key_2fa
        pools_users {
          id
          pool
          user_address
          x_user_id
        }
      }
    }
  }
`;
export const LOGIN_WITH_TWO_FACTOR = gql`
  query MyQuery($email: String!, $key: String!, $token: String!) {
    login_verify_2fa(email: $email, key: $key, token: $token) {
      status
      message
      data {
        id
        email
        name
        is_2fa
        is_setup_account
        key_2fa
        telegram
        token
        pools_users {
          user_address
        }
      }
    }
  }
`;

export const GET_ACCOUNT_BY_USER_ID = gql`
  query getAccountByUserID($user_id: Int) {
    user_accounts(where: { user_id: { _eq: $user_id } }) {
      id
      user_id
      bot_id
      created_at
      updated_at
    }
  }
`;

// LIST COIN
export const GET_DATA_POOL = gql`
  query MyQuery {
    get_pools {
      id
      base
      base_name
    }
  }
`;
export const GET_DATA_CAHRT = gql`
  query MyQuery {
    pools_balances_this_week {
      time
      pool
      net_eth
      net_btc
      net_usd
    }
  }
`;

// DATA WEKKLY
export const GET_POOLS_WEKKLY = gql`
  query MyQuery($poolId: Int!) {
    get_pool_stats(poolId: $poolId) {
      status
      message
      data {
        weekly
        apy
      }
    }
  }
`;

//POOLS_BALANCE_WEEK;
export const GET_POOLS_BALANCE_WEEK = gql`
  query MyQuery($poolId: Int!, $xUserId: Int!) {
    get_pool_balance_user_week(poolId: $poolId, xUserId: $xUserId) {
      status
      message
      data {
        net_btc
        net_eth
        net_usd
        pool
        time
        pool_user
      }
    }
  }
`;

export const GET_USER_BY_UID = gql`
  query MyQuery($uid: String) {
    users(where: { uid: { _eq: $uid } }) {
      id
      uid
      email
      telegram
      referral
      payment
      team
      access
      affiliate
      share
      created_at
      updated_at
    }
  }
`;

// MUTATION
export const GET_QR_CODE = gql`
  mutation MyMutation($email: String!, $token: String!) {
    gen_2fa_xtremex(email: $email, token: $token) {
      status
      message
      data {
        code_secret
        qrcode_image
      }
    }
  }
`;
export const SAVE_TELEGRAM = gql`
  mutation MyMutation(
    $auth_date: Int!
    $hash: String!
    $userId: Int!
    $id: Int!
    $first_name: String
    $last_name: String
    $photo_url: String
    $username: String!
  ) {
    save_telegram_account(
      auth_date: $auth_date
      hash: $hash
      id: $id
      userId: $userId
      first_name: $first_name
      last_name: $last_name
      photo_url: $photo_url
      username: $username
    ) {
      message
      status
    }
  }
`;

// Submit 2 factor
export const SUBMIT_TWO_FACTOR = gql`
  mutation MyMutation(
    $email: String!
    $code: String!
    $secret: String!
    $token: String!
  ) {
    set_2fa_status(
      email: $email
      status: true
      code: $code
      secret: $secret
      token: $token
    ) {
      message
      status
    }
  }
`;
export const DELETE_TWO_FACTOR = gql`
  mutation MyMutation($email: String!, $token: String!) {
    set_2fa_status(email: $email, status: false, token: $token) {
      message
      status
    }
  }
`;
// Create user
export const CREATE_USER = gql`
  mutation CreateUser($email: String!, $password: String!, $referal: String!) {
    create_user_xtremex(email: $email, password: $password, referal: $referal) {
      message
      status
    }
  }
`;

// Forgot password
export const FORGOT_PASS = gql`
  query MyQuery($email: String!) {
    forgot_pass_xtremex(email: $email) {
      message
      status
    }
  }
`;

// Forgot pass key
export const KEY_FORGOT_PASS = gql`
  query MyQuery($code: String!, $email: String!) {
    key_forgot_pass_xtremex(code: $code, email: $email) {
      status
      message
      data {
        key
      }
    }
  }
`;

// Reset password
export const RESET_PASSWORD = gql`
  mutation MyMutation(
    $code: String!
    $email: String!
    $key: String!
    $password: String!
  ) {
    reset_password_xtremex(
      code: $code
      email: $email
      key: $key
      password: $password
    ) {
      message
      status
    }
  }
`;

// VERIFING EMAIL
export const VERIFYING_EMAIL = gql`
  query VerifyEmail($email: String!, $verifyCode: String!) {
    verify_code_xtremex(email: $email, verifyCode: $verifyCode) {
      status
      message
      data {
        id
        name
        email
        telegram
        is_2fa
        is_setup_account
        token
      }
    }
  }
`;

// RESEND EMAIL FROM GMAIL
export const RESEND_EMAIL = gql`
  mutation MyMutation($email: String!) {
    resend_verification_mail(email: $email) {
      status
      message
    }
  }
`;

// GET USER PROFILE BY EMAIL
export const GET_USER_BY_EMAIL = gql`
  query GetUser($email: String) {
    x_users(where: { email: { _eq: $email } }) {
      id
      email
      name
      telegram
      created_at
      updated_at
    }
  }
`;

// GET USER INFO BY ID
export const GET_USER_BY_ID = gql`
  query GetUser($id: String) {
    x_users(where: { id: { _eq: $id } }) {
      id
      email
      name
      telegram
      created_at
      updated_at
    }
  }
`;

// Update user info
export const UPDATE_USER_BY_EMAIL = gql`
  mutation updateUser($email: String, $id: String) {
    update_x_users(
      where: { email: { _eq: $email } }
      _set: { email: $email, id: $id }
    ) {
      returning {
        id
        email
        name
        telegram
        created_at
        updated_at
        created_at
        updated_at
      }
    }
  }
`;
//update password
export const UPDATE_PASSWORD = gql`
  mutation CheckAddressWallet($userId: Int!, $password: String!) {
    update_password_xtremex(userId: $userId, password: $password) {
      status
      message
    }
  }
`;
export const UPDATE_PROFILE_USER = gql`
  mutation CheckAddressWallet($userId: Int!, $email: String!) {
    update_profile_xtremex(userId: $userId, email: $email) {
      status
      message
    }
  }
`;
export const UPDATE_SETUP_USER = gql`
  mutation UpdateIsSetup($userId: Int!, $isSetup: Boolean!) {
    update_is_setup(isSetup: $isSetup, userId: $userId) {
      message
      status
    }
  }
`;

// Generate 2FA
export const GENERATE_2FA = gql`
  mutation Generate2fa($email: String!) {
    gen_2fa_xtremex(email: $email) {
      data {
        code_secret
        qrcode_image
      }
    }
  }
`;

// Save coinbase credential
export const SAVE_COINBASE = gql`
  mutation SaveCoinbase($api: String!, $secret: String!, $token: String!) {
    save_coinbase_token(api: $api, secret: $secret, token: $token) {
      message
      status
    }
  }
`;
export const COINBASE_CALLBACK = gql`
  mutation SaveCoinbaseData($userId: Int!, $code: String!, $state: String!) {
    save_coinbase_token(userId: $userId, code: $code, state: $state) {
      status
      message
    }
  }
`;

// GET USER INFO BY ID
export const GET_USER = gql`
  query GetUser($userId: Int!) {
    get_user_extremex(userId: $userId) {
      data {
        name
        email
        telegram
        coinbase_token
      }
    }
  }
`;

export const GET_USER_QUERY = gql`
  query ($email: String!) {
    users(where: { email: { _eq: $email } }) {
      email
      telegram
      referral
      team
      access
      payment
    }
  }
`;
// GET POOLS
export const GET_POOL_USER_LAST = gql`
  subscription MySubscription($userId: Int!) {
    pools_users_balances_lasts(where: { x_user_id: { _eq: $userId } }) {
      pool
      pool_user
      x_user_id
      balance
      balance_usd
      timestamp
    }
  }
`;

//GET REFERRAL ID
export const GET_REFERRAL_ID = gql`
  query MyQuery($refId: String!) {
    x_users(where: { ref_id: { _eq: $refId } }) {
      name
      email
    }
  }
`;

//GET_TOTAL_REFERRAL
export const GET_TOTAL_REFERRAL = gql`
  query MyQuery($xID: Int!) {
    x_users_referrals(where: { id: { _eq: $xID } }) {
      id
      referrals
      name
      email
    }
  }
`;

//Change Email And Verify
export const UPDATE_EMAILVER_USER = gql`
  mutation UpdateEmailverXtremex($userId: Int!, $email: String!) {
    update_emailver_xtremex(userId: $userId, email: $email) {
      status
      message
    }
  }
`;

//Request Withdrawal
export const REQUEST_WITHDRAWAL = gql`
  mutation RequestWithdrawal($addressOut: String!,$amount: Float!,$poolId: Int!, $xUserId: Int!) {
    request_withdrawal_xtremex(addressOut: $addressOut, amount: $amount, poolId: $poolId, xUserId: $xUserId) {
      data
      message
      status
    }
  }
`;

//Get Balance Query
export const QUERYGETPOOLBALLAST = gql`
query GetPoolbalance($xuserid: Int!) {
  pools_users_balances_lasts(where: {x_user_id: {_eq: $xuserid}}) {
    balance
    balance_usd
    internal
    user_id
    x_user_id
    timestamp
    pool
    pool_user
  }
}
`;
