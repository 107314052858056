import React, { useState, useEffect, useCallback } from "react";
import TopBar from "../../components/topbar";
// import { useLocalStorage } from "../useLocalStorage";
import ETH from "../../assets/images/ethereum-eth-logo.png";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { withAuthorization, withEmailVerification } from "../../session";
import { compose } from "recompose";
import { withSnackbar } from "notistack";
import * as ROUTES from "../../constants/routes";
import {
  GET_DATA_POOL,
  GET_POOLS_WEKKLY,
  GET_POOL_USER_LAST,
} from "../../graphql/index";
import { useLazyQuery, useQuery, useSubscription } from "@apollo/client";

const pools = (props) => {
  const [isUser] = useState(parseInt(localStorage.getItem("userId")));
  const [Balance, setValueBalance] = useState(0);
  const [PoolsId, setPoolsId] = useState(1);
  const [errValue, setErrValue] = useState("");
  const [data_apy, set_data_apy] = useState("");
  const [isHasBalance, setIsHasBalance] = useState(false);

  const [data_list_pools, { data: getPools }] = useLazyQuery(GET_DATA_POOL);

  const { data: get_data_wekkly } = useQuery(GET_POOLS_WEKKLY, {
    variables: {
      poolId: PoolsId,
    },
  });

  const { data: pool_users_balance, loading: loading_pool_users_balance } =
    useSubscription(GET_POOL_USER_LAST, {
      variables: { x_user_id: parseInt(isUser) },
    });

  useEffect(() => {
    data_list_pools();
    GetList();
    if (
      loading_pool_users_balance !== undefined &&
      !loading_pool_users_balance &&
      pool_users_balance !== undefined &&
      pool_users_balance.pools_users_balances_lasts !== undefined
    ) {
      if (pool_users_balance.pools_users_balances_lasts.length > 0) {
        let bal = pool_users_balance.pools_users_balances_lasts[0].balance;
        if (bal > 0) {
          setValueBalance(bal);
          setIsHasBalance(true);
        } else {
          setValueBalance(bal);
          setIsHasBalance(false);
        }
      } else {
        setIsHasBalance(false);
        setValueBalance(0);
      }
    } else {
      setErrValue("Loading...");
    }
  }, [
    data_apy,
    GetList,
    get_data_wekkly,
    set_data_apy,
    setValueBalance,
    setErrValue,
    pool_users_balance,
    loading_pool_users_balance,
  ]);

  const GetList = useCallback(() => {
    if (get_data_wekkly) {
      if (get_data_wekkly.get_pool_stats) {
        set_data_apy(get_data_wekkly.get_pool_stats);
      }
    }
  }, [get_data_wekkly, set_data_apy]);

  return (
    <>
      <TopBar path={props.location.pathname} />
      <main className="app-main">
        <header className="header-app">
          <h2 className="title-page">Pools</h2>
        </header>
        {getPools != null &&
        data_apy.data != undefined &&
        Array.isArray(getPools.get_pools) ? (
          getPools.get_pools.map((coin) => {
            let data = data_apy.data;
            let Price = data.weekly * Balance;
            return (
              <div className="pool-wrp">
                <div className="pool-main">
                  <div className="pool-space">
                    <div className="Logo-pool">
                      <Link className="btn-pool" to={ROUTES.POOLS_DETAIL}>
                        {/* <img src={coin.image} alt="" className="logo" /> */}
                      </Link>
                    </div>
                    <div className="Logo spc">
                      <Link className="btn-pool" to={ROUTES.POOLS_DETAIL}>
                        <img src={ETH} alt="" className="logo" />
                      </Link>
                    </div>
                    <div className="name-pool1">
                      <Link className="btn-pool" to={ROUTES.POOLS_DETAIL}>
                        {coin.base_name}
                      </Link>
                    </div>
                    <div className="name-pool2">
                      ({coin.base.toUpperCase()})
                    </div>
                  </div>
                  <div className="pools-child">
                    <div className="space spc">
                      <div className="pool-name-percent">My Total Balance:</div>
                      <div className="pool-balance">{Price}</div>
                    </div>
                    <div className="space spc">
                      <div className="pool-name-percent">Current APY</div>
                      <div className="pool-percent">
                        {JSON.stringify(data.apy)}%
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`pools-arrow`}>
                  <Link className="btn-pool" to={ROUTES.POOLS_DETAIL}>
                    <FontAwesomeIcon
                      className="btn-arrow"
                      icon={faAngleRight}
                      size="lg"
                    />
                  </Link>
                </div>
              </div>
            );
          })
        ) : (
          <div className="pool-wrp">
            <div className="pool-main">
              <p>No pools data</p>
            </div>
          </div>
        )}
      </main>
    </>
  );
};
const condition = (authUser) => !!authUser;

export default compose(withSnackbar, withEmailVerification)(pools);
