import React, { useState, useEffect } from "react";
import logo from "../../assets/images/xtremex-logo.jpg";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";

import { compose } from "recompose";
import { withSnackbar } from "notistack";
import { COINBASE_CALLBACK } from "../../graphql";
import { withEmailVerification } from "../../session";
// import { useLazyQuery } from "@apollo/client";

const queryParams = new URLSearchParams(window.location.search);
let token = localStorage.getItem("token");

const authLink = createHttpLink({
  uri: "https://api.xtremex.io/",
  headers: {
    authorization: `Bearer ${token}`,
  },
});

const client = new ApolloClient({
  link: authLink,
  cache: new InMemoryCache().restore(window.__APOLLO_STATE__),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
      errorPolicy: "ignore",
    },
    query: {
      fetchPolicy: "cache-first",
      errorPolicy: "all",
    },
    subscribe: {
      fetchPolicy: "cache-first",
      errorPolicy: "ignore",
    },
  },
});

const CoinBase = (props) => {
  const [isCode] = useState(queryParams.get("code"));

  const [isUser] = useState(parseInt(localStorage.getItem("userId")));
  const [setStatus] = useState(false);
  const [setMessage] = useState("");
  const [setLoading] = useState(false);

  useEffect(() => {
    CoinBase();
  }, []);

  const CoinBase = () => {
    setLoading(true);
    let Data = { userId: isUser, code: isCode, state: "" };
    client
      .mutate({
        variables: Data,
        mutation: COINBASE_CALLBACK,
      })
      .then((result) => {
        if (result.data) {
          if (result.data.save_coinbase_token.status === true) {
            setStatus(result.data.save_coinbase_token.status);
            setMessage(result.data.save_coinbase_token.message);
            setLoading(false);
          } else {
            setStatus(true);
            setMessage(result.data.save_coinbase_token.message);
            setLoading(false);
          }
        }
      })
      .catch((er) => {
        setStatus(true);
      });
  };

  return (
    <>
      <div className="auth-wrp-outer">
        <div className="auth-wrp-inner">
          <h1 className="is-sr-only">Xtremex</h1>
          <img src={logo} className="auth-logo-verify" alt="Xtremex" />
          <div className="auth-body single">
            <div className="auth-wrp-verify">
              {isCode !== "" && isUser !== null ? (
                <div className="box-verify">
                  <CircularProgress color="primary" />
                  <h2 className="title">Connect account verified!</h2>
                  <p>&nbsp;</p>
                  <p>Window is closing...</p>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default compose(withSnackbar, withEmailVerification)(CoinBase);
