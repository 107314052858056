import React from "react";
import { compose } from "recompose";
import { withAuthorization, withEmailVerification } from "../../session";
import { AreaChart, Area, Tooltip, ResponsiveContainer } from "recharts";
import { withSnackbar } from "notistack";

import * as storage from "../../storage";
import TopBar from "../../components/topbar";
import { graphql } from "react-apollo";
import MyPools from "./my-pools";
import CoinGecko from "coingecko-api";
import Chart from "./chartTemp";
import ETH from "../../assets/images/ethereum-eth-logo.png";
import BTC from "../../assets/images/bitcoin-btc-logo.png";
import BNB from "../../assets/images/binance-logo.png";
import DepositIcon from "../../assets/images/deposit.png";
import WithdrawalIcon from "../../assets/images/withdrawal.png";

import { GET_USER_BY_EMAIL } from "../../graphql";
import Chart_Portofolio from "./chart-dashboard";
import Modal from "react-modal";
import ReactModal from "react-modal";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CSSTransition } from "react-transition-group";

ReactModal.defaultStyles.overlay.backgroundColor = "transparent";

const customStyles = {
  content: {
    top: "60%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    padding: "0px",
  },
};
const coinGeckoClient = new CoinGecko();

let authUser = storage.getAuthUser() != null ? storage.getAuthUser() : "";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      botAccountIsEmpty: false,
      tabIndex: 0,
      id: 0,
      data: [
        {
          name: "Page A",
          btc: 1000,
          etc: 2400,
          bnb: 2400,
        },
        {
          name: "Page B",
          btc: 2000,
          etc: 1398,
          bnb: 2210,
        },
        {
          name: "Page C",
          btc: 2000,
          etc: 9800,
          bnb: 2290,
        },
        {
          name: "Page D",
          btc: 2780,
          etc: 3908,
          bnb: 2000,
        },
        {
          name: "Page E",
          btc: 1890,
          etc: 4800,
          bnb: 2181,
        },
        {
          name: "Page F",
          btc: 2390,
          etc: 3800,
          bnb: 2500,
        },
        {
          name: "Page G",
          btc: 3490,
          etc: 4300,
          bnb: 2100,
        },
      ],
      modalIsOpen: false,
      currencyButton: ["BTC", "ETH", "USDC"],
      defaultCurrency: "BTC",
    };
  }

  componentDidMount() {
    if (this.props.accountUser) {
      this.setState({
        botAccountIsEmpty: this.props.accountUser.length > 0 ? false : true,
      });
    }
    this.props.getUserByEmail
      .refetch({ email: this.props.authUser.email })
      .then(({ data }) => {
        this.setState({
          id: data.x_users[0].id,
        });
      });
  }

  onSelect = (tabIndex) => {
    this.setState({
      selectIndex: tabIndex,
    });
  };

  onDepositBtnClick = (btn, target) => {
    this.props.enqueueSnackbar("This module is under development", {
      persist: true,
      variant: "info",
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
    });
  };

  onWithdrawalBtnClick = (btn, target) => {
    this.props.enqueueSnackbar("This module is under development", {
      persist: true,
      variant: "info",
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
    });
  };
  openModal = () => {
    this.setState({ modalIsOpen: true });
  };
  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };
  copyToClipboard = () => {
    navigator.clipboard.writeText("0xAd9dA14080924d5eEcE45863fBA13fEfEF035034");
    this.props.enqueueSnackbar("Copy success", {
      persist: true,
      variant: "success",
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
    });
  };
  render() {
    return (
      <>
        <TopBar path={this.props.location.pathname} />
        <main
          className={`app-main${this.state.botAccountIsEmpty ? " fcc" : ""}`}
        >
          <header className="header-app">
            <h2 className="title-page">Dashboard</h2>
          </header>
          <div className={`portfolio-wrp`}>
            <Chart_Portofolio
              user_address={authUser.pools_users[0].user_address}
            />
          </div>
          <header className="header-app">
            <h2 className="title-page">My Pools</h2>
          </header>
          <div>
            <MyPools />
          </div>
        </main>
      </>
    );
  }
}

const condition = (authUser) => !!authUser;

export default compose(
  graphql(GET_USER_BY_EMAIL, { name: "getUserByEmail" }),
  withEmailVerification,
  withAuthorization(condition),
  withSnackbar
)(Dashboard);
