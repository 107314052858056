import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  TextField,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { compose } from "recompose";
import { withSnackbar } from "notistack";
import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { withAuthorization, withEmailVerification } from "../../session";
import * as ROUTES from "../../constants/routes";
import logo from "../../assets/images/logo.png";
import {
  faChevronLeft,
  faEyeSlash,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as storage from "../../storage";
import { UPDATE_PASSWORD } from "../../graphql";
let authUser = storage.getAuthUser() != null ? storage.getAuthUser() : "";

const authLink = createHttpLink({
  uri: "https://api.xtremex.io/",
  headers: {
    authorization: `Bearer ${authUser.token}`,
  },
});
//command
const client = new ApolloClient({
  link: authLink,
  cache: new InMemoryCache().restore(window.__APOLLO_STATE__),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "network-only",
      errorPolicy: "ignore",
    },
    query: {
      fetchPolicy: "network-only",
      errorPolicy: "all",
    },
    subscribe: {
      fetchPolicy: "network-only",
      errorPolicy: "ignore",
    },
  },
});
class ChangePassword extends React.Component {
  constructor() {
    super();
    this.state = {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      errorPasswordNew: "",
      errorConfirmPassword: "",
      loading: false,
      showPassword: false,
      showPasswordOld: false,
      showPasswordConf: false,
    };
  }

  loading = (status) => {
    this.setState({
      loading: status,
    });
  };

  async onChangeInput(e) {
    this.setState({ [e.target.id]: e.target.value });
  }

  handleClickShowPassword = (e) => {
    if (e === "oldPassword") {
      this.setState({
        showPasswordOld: !this.state.showPasswordOld,
      });
    } else if (e === "newPassword") {
      this.setState({
        showPassword: !this.state.showPassword,
      });
    } else if (e === "confirmPassword") {
      this.setState({
        showPasswordConf: !this.state.showPasswordConf,
      });
    }
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { newPassword, confirmPassword } = this.state;
    if (
      newPassword === confirmPassword &&
      newPassword !== "" &&
      confirmPassword !== ""
    ) {
      this.setState({
        loading: true,
      });
      client
        .mutate({
          variables: { userId: authUser.id, password: this.state.newPassword },
          mutation: UPDATE_PASSWORD,
        })
        .then((result) => {
          if (result.data.update_password_xtremex.status === true) {
            this.setState({
              loading: false,
            });
            this.props.enqueueSnackbar("Change password success", {
              persist: true,
              variant: "success",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
            });
          }
        })
        .catch((er) => {
          this.setState({
            loading: false,
          });
        });
    } else if (newPassword === "" && confirmPassword === "") {
      this.setState({
        loading: false,
      });
      this.props.enqueueSnackbar("Field should not be empty", {
        persist: true,
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    } else {
      this.setState({
        loading: false,
      });
      this.props.enqueueSnackbar("Password should match", {
        persist: true,
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    }
  };
  render() {
    return (
      <>
        <div className="auth-wrp-outer">
          <div className="auth-wrp-inner">
            <h1 className="is-sr-only">Xtremex</h1>
            <img
              src={logo}
              className="auth-logo-title is-tablet"
              alt="Xtremex"
            />
            <div className="auth-body default">
              <div className="btn-back-page-before">
                <Link className="icon-circle" to={ROUTES.MY_SETTINGS}>
                  <FontAwesomeIcon className="icon" icon={faChevronLeft} />
                </Link>
                <h2 className="text">Change Password</h2>
              </div>
              <div className="auth-wrp">
                <div className="box-default is-center">
                  <form className="form-cc-wrp" noValidate autoComplete="off">
                    {/* <div className="form-group">
                      <TextField
                        id="oldPassword"
                        type={this.state.showPasswordOld ? "text" : "password"}
                        className="input is-fullwidth"
                        value={this.state.oldPassword}
                        label="enter Old Password"
                        onChange={(e) => this.onChangeInput(e)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() =>
                                  this.handleClickShowPassword("oldPassword")
                                }
                                onMouseDown={this.handleMouseDownPassword}
                              >
                                {this.state.showPasswordOld ? (
                                  <FontAwesomeIcon
                                    className="icon-end"
                                    icon={faEyeSlash}
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="icon-end"
                                    icon={faEye}
                                  />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div> */}
                    <div className="form-group">
                      <TextField
                        error={this.state.errorPasswordNew ? true : false}
                        id="newPassword"
                        type={this.state.showPassword ? "text" : "password"}
                        className="input is-fullwidth"
                        value={this.state.newPassword}
                        label="enter New Password"
                        onChange={(e) => this.onChangeInput(e)}
                        helperText={this.state.errorPasswordNew}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() =>
                                  this.handleClickShowPassword("newPassword")
                                }
                                onMouseDown={this.handleMouseDownPassword}
                              >
                                {this.state.showPassword ? (
                                  <FontAwesomeIcon
                                    className="icon-end"
                                    icon={faEyeSlash}
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="icon-end"
                                    icon={faEye}
                                  />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <TextField
                        error={this.state.errorConfirmPassword ? true : false}
                        id="confirmPassword"
                        type={this.state.showPasswordConf ? "text" : "password"}
                        className="input is-fullwidth"
                        value={this.state.confirmPassword}
                        label="enter Confirm Password"
                        onChange={(e) => this.onChangeInput(e)}
                        helperText={this.state.errorConfirmPassword}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() =>
                                  this.handleClickShowPassword(
                                    "confirmPassword"
                                  )
                                }
                                onMouseDown={this.handleMouseDownPassword}
                              >
                                {this.state.showPasswordConf ? (
                                  <FontAwesomeIcon
                                    className="icon-end"
                                    icon={faEyeSlash}
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="icon-end"
                                    icon={faEye}
                                  />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </form>
                  <Button
                    disabled={this.state.loading}
                    className="button btn-block is-primary btn-submit"
                    type="submit"
                    onClick={this.handleSubmit}
                  >
                    {this.state.loading ? "SAVING..." : "SAVE NEW PASSWORD"}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const condition = (authUser) => !!authUser;

export default withSnackbar(
  compose(
    withEmailVerification,
    withAuthorization(condition)
  )(withSnackbar(ChangePassword))
);
