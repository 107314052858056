import React from "react";
import { withSnackbar } from "notistack";
import { compose } from "recompose";
import { TextField } from "@material-ui/core";
import buffering from "../../assets/images/buffering.gif";
import coinbase from "../../assets/images/coinbase.png";
import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import * as storage from "../../storage";
import { Link } from "react-router-dom";
import {
  GET_QR_CODE,
  SUBMIT_TWO_FACTOR,
  UPDATE_SETUP_USER,
  SAVE_TELEGRAM,
  GET_USER,
} from "../../graphql";
import { withAuthorization, withEmailVerification } from "../../session";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTelegramPlane } from "@fortawesome/free-brands-svg-icons";
import TelegramLoginButton from "react-telegram-login";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

let openedWindow;

let authUser = storage.getAuthUser() != null ? storage.getAuthUser() : "";

const authLink = createHttpLink({
  uri: "https://api.xtremex.io/",
  headers: {
    authorization: `Bearer ${authUser.token}`,
  },
});
//command
const client = new ApolloClient({
  link: authLink,
  cache: new InMemoryCache().restore(window.__APOLLO_STATE__),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "network-only",
      errorPolicy: "ignore",
    },
    query: {
      fetchPolicy: "network-only",
      errorPolicy: "all",
    },
    subscribe: {
      fetchPolicy: "network-only",
      errorPolicy: "ignore",
    },
  },
});

class SetUp extends React.Component {
  verifyCoinbaseTimer = null;
  state = {
    number: [
      { id: 1, name: "Two Factor" },
      { id: 2, name: "Telegram" },
      { id: 3, name: "Coinbase" },
      { id: 4, name: "Done" },
    ],
    defaultNumb: 1,
    progressCompleted: 1,
    check: false,
    errorCode: "",
    code: "",
    loading: false,
    imageUrl: buffering,
    statusUpdate: 0,
    secretCode: "",
    visibleSC: false,
    telegramName: "",
    twofaVerified: false,
    coinbaseVerified: false,
    nextBtnState: true,
    loadingCoinbase: false,
  };
  componentDidUpdate() {
    if (this.state.statusUpdate === 0) {
      this.getQrCode();
      this.setState({ statusUpdate: 2 });
    } else {
      return false;
    }
  }

  changeProgress = (defaultNumb) => {
    if (defaultNumb < 4) {
      const { number } = this.state;
      const newNumber = [...number];
      newNumber[this.state.defaultNumb].active = true;
      return this.setState({
        number: newNumber,
        defaultNumb: defaultNumb + 1,
      });
    } else {
      return false;
    }
  };

  code = (event) => {
    this.setState({ code: event.target.value.toString().trim() }, () => {
      this.validateCode();
    });
  };

  validateCode = () => {
    if (this.state.code.trim().length < 1) {
      this.setState({
        errorCode: "Code is required.",
      });
    } else if (this.state.code.trim().length < 6) {
      this.setState({
        errorCode: "Code must be at least 6 characters.",
      });
    } else {
      this.setState({
        errorCode: "",
      });
      return true;
    }
    return false;
  };

  validate2 = () => {
    return this.validateCode();
  };

  getQrCode = () => {
    client
      .mutate({
        variables: { email: authUser.email, token: authUser.token },
        mutation: GET_QR_CODE,
      })
      .then((result) => {
        this.setState({
          imageUrl: result.data.gen_2fa_xtremex.data.qrcode_image,
          secretCode: result.data.gen_2fa_xtremex.data.code_secret,
        });
      })
      .catch((er) => {});
  };

  submitTwoFactor = (e) => {
    e.preventDefault();
    if (!this.validate2()) {
      return false;
    }
    this.setState({ loading: true });
    client
      .mutate({
        variables: {
          email: authUser.email,
          code: this.state.code,
          secret: this.state.secretCode,
          token: authUser.token,
        },
        mutation: SUBMIT_TWO_FACTOR,
      })
      .then((result) => {
        this.setState({ loading: false });
        if (result.data.set_2fa_status.status) {
          const data = {
            id: authUser.id,
            email: authUser.email,
            name: authUser.name,
            telegram: authUser.telegram,
            is_2fa: true,
            is_setup_account: authUser.is_setup_account,
            token: authUser.token,
            key_2fa: authUser.key_2fa,
          };
          storage.setAuthUser(data);
          this.props.enqueueSnackbar("2 factor authentication is setup", {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
          this.setState({ defaultNumb: 2 });
        } else {
          this.props.enqueueSnackbar(result.data.set_2fa_status.message, {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
        }
      })
      .catch((er) => {
        this.setState({ loading: false });
      });
  };
  stepOneProccess = () => {
    if (this.state.check) {
      this.submitTwoFactor();
    } else {
      this.setState({ defaultNumb: 2 });
    }
  };

  setIsSetup = () => {
    let that = this;
    client
      .mutate({
        variables: { userId: authUser.id, isSetup: true },
        mutation: UPDATE_SETUP_USER,
      })
      .then((result) => {
        authUser.is_setup_account = true;
        storage.setAuthUser(authUser);
        that.props.history.push("/dashboard");
      })
      .catch((er) => {});
  };
  saveDataTelegram = (user) => {
    let user_telegram = Object.assign({}, user);
    user_telegram.userId = authUser.id;
    if (typeof user_telegram.last_name === "undefined") {
      user_telegram.last_name = "";
    }

    this.setState({ loading: true, telegramName: user_telegram.first_name });
    client
      .mutate({
        variables: user_telegram,
        mutation: SAVE_TELEGRAM,
      })
      .then((result) => {
        this.setState({ loading: false });
        if (result.data.save_telegram_account.status) {
          this.props.enqueueSnackbar("Telegram profile is saved", {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
          this.setState({ defaultNumb: 3 });
        } else {
          this.props.enqueueSnackbar(
            result.data.save_telegram_account.message,
            {
              variant: "error",
              anchorOrigin: { vertical: "bottom", horizontal: "center" },
            }
          );
        }
      })
      .catch((er) => {
        this.setState({ loading: false });
      });
  };
  removeDataTelegram = () => {
    return false;
  };
  buttonProgress = () => {
    switch (this.state.defaultNumb) {
      case 1:
        this.stepOneProccess();
        break;

      case 2:
        this.setState({ defaultNumb: 3 });
        break;

      case 3:
        this.setState({ defaultNumb: 4 });
        break;

      case 4:
        this.setIsSetup();
        break;

      default:
        alert("tidak ada");
        break;
    }
  };

  openWindow = () => {
    let redirectUrl = encodeURI(process.env.REACT_APP_COINBASE_OAUTH_REDIRECT);
    let clientId = process.env.REACT_APP_COINBASE_OAUTH_CLIENT_ID;
    localStorage.setItem("userId", authUser.id);
    localStorage.setItem("token", authUser.token);

    openedWindow = window.open(
      `https://www.coinbase.com/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUrl}&response_type=code&scope=wallet%3Auser%3Aread`,
      "Coinbase",
      "width=420,height=250,menubar=no,resizable=no,scrollbars=no,status=no,location=no"
    );
  };

  closeOpenedWindow = () => {
    openedWindow.close();
  };

  redirectTo = () => {
    this.openWindow();

    this.setState({ loading: true, loadingCoinbase: true });

    let that = this;
    let toID = setInterval(() => {
      client
        .mutate({
          variables: { userId: authUser.id },
          mutation: GET_USER,
        })
        .then((result) => {
          if (result.data.get_user_extremex.data.coinbase_token !== "") {
            clearInterval(toID);
            this.closeOpenedWindow();
            that.setState({
              loading: false,
              loadingCoinbase: false,
              coinbaseVerified: true,
              defaultNumb: 4,
            });
          }
        });
    }, 3000);
  };
  render() {
    let {
      loading,
      defaultNumb,
      number,
      check,
      imageUrl,
      telegramName,
      errorCode,
      secretCode,
      coinbaseVerified,
    } = this.state;

    return (
      <div className="login-container-edit">
        <div className="content-wrapper-edit">
          <div className="box-content-edit">
            <div className="box-step-setup">
              {number.map((value, key) => {
                if (defaultNumb === value.id) {
                  return <div className="dot-step"></div>;
                } else {
                  return (
                    <div className="dot-step-round">
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          this.setState({ defaultNumb: value.id });
                        }}
                      >
                        &nbsp;
                      </div>
                    </div>
                  );
                }
              })}
            </div>
            <div className="box-single">
              {/* STEP 1 */}
              {defaultNumb === 1 && (
                <>
                  <div className="text-2fa-edit ">Set Up 2FA</div>

                  <div className="text-edit-step1">Step 1 : Get the App</div>

                  <div className="text-edit-step2">
                    Download and install Google Authenticator (IOS or Android)
                    or Authy (IOS or Android)
                  </div>

                  <div className="text-edit-step3">
                    Step 2 : Scan this Barcode
                  </div>

                  <div className="text-edit-step4">
                    Tap the "+" icon in your authenticator app and scan the
                    barcode using your device's camera
                  </div>

                  <div className="text-edit-step5">
                    <img alt="QR code" src={imageUrl} />
                  </div>

                  <div className="text-edit-step6">
                    If you can't scan the QR code, enter this code:
                  </div>

                  <div className="text-edit-step7">{secretCode}</div>

                  <div className="text-edit-step8">Step 3 : Enter Code</div>

                  <div className="text-edit-step9">
                    Enter the 6 digit code generated by your authenticator app.
                  </div>

                  <form
                    className="auth-form-wrp form-edit-step1"
                    onSubmit={this.submitTwoFactor}
                    noValidate
                    autoComplete="off"
                  >
                    <div className="form-group">
                      <TextField
                        error={errorCode ? true : false}
                        id="code"
                        className="input is-fullwidth"
                        disabled={loading}
                        label="Enter 6 digit code"
                        helperText={errorCode}
                        onChange={this.code}
                        inputProps={{ maxLength: 6 }}
                      />
                    </div>
                    <button
                      disabled={this.state.loading}
                      type="submit"
                      className=" btn-signup-edit"
                    >
                      {this.state.loading ? "LOADING..." : "SETUP"}
                    </button>
                    <Link
                      className="link-forgot-edit"
                      onClick={() =>
                        this.setState({
                          defaultStep: this.state.defaultStep + 1,
                        })
                      }
                    >
                      <div className="wrap-link-edit">
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            this.setState({ defaultNumb: 2 });
                          }}
                        >
                          Skip
                        </div>
                      </div>
                    </Link>
                  </form>
                </>
              )}
              {/* END STEP 1*/}

              {/* STEP 1 */}
              {defaultNumb === 2 && (
                <>
                  <div className="text-2fa-edit">Connect Telegram</div>

                  <div className="telegram-wrap-icon-edit">
                    <FontAwesomeIcon
                      icon={faTelegramPlane}
                      className="telegram-icon-edit"
                    />
                  </div>

                  <div className="text-edit-step3">
                    Join Xtremex.io's Telegram
                  </div>

                  <div className="text-edit-step4">
                    Get Notifications, chat and pool information via Xtremex's
                    Telegram Groups
                  </div>

                  <div className="text-edit-step3">
                    Step 1 : Connect Your Telegram Account{" "}
                  </div>

                  {telegramName !== "" ? (
                    <button
                      className="button is-telegram "
                      onClick={() => this.removeDataTelegram()}
                    >
                      <FontAwesomeIcon
                        className="icon-telegram"
                        icon={faTelegramPlane}
                      />
                      Disconnect as {telegramName}
                    </button>
                  ) : (
                    <>
                      <TelegramLoginButton
                        botName="xtremex_bot"
                        className="telegram-btn"
                        usePic={false}
                        dataOnauth={(user) => {
                          this.saveDataTelegram(user);
                        }}
                      />
                    </>
                  )}

                  <div className="text-edit-step3">
                    Step 2 : Join Xtremex's Telegram Group (optional){" "}
                  </div>

                  <button
                    disabled={this.state.loading}
                    className=" btn-join-telegram-group "
                  >
                    <div style={{ fontSize: "12px" }}>
                      Join Xtremex's Telegram Group
                    </div>
                  </button>

                  <form
                    className="auth-form-wrp form-edit-step1"
                    onSubmit={() =>
                      this.setState({ defaultStep: this.state.defaultStep + 1 })
                    }
                    noValidate
                    autoComplete="off"
                  >
                    <button
                      disabled={this.state.loading}
                      type="submit"
                      className="btn-signup-edit"
                      onClick={() => {
                        this.setState({ defaultNumb: 3 });
                      }}
                    >
                      {this.state.loading ? "LOADING..." : "SKIP"}
                    </button>
                  </form>
                </>
              )}
              {/* END STEP 2*/}

              {/* STEP 3 */}
              {defaultNumb === 3 && (
                <>
                  <div className="text-2fa-edit plus-margin">
                    Connect to Wallet
                  </div>

                  <p className="text-edit-step4">
                    By connecting a wallet, you agree to Xtremex{" "}
                    <span className="aqua-color">terms of service</span> and
                    acknowledge that you have read and understand the Xtremex{" "}
                    <span className="aqua-color">disclaimer</span>
                  </p>

                  <div className="text-edit-step3">
                    Click button below to connect to a wallet
                  </div>

                  <div className="btn-connect-app">
                    <div className="white-color">MetaMask</div>
                  </div>

                  <div className="btn-connect-app">
                    <div className="white-color">WalletConnect</div>
                  </div>

                  <button
                    className="btn-connect-coinbase"
                    onClick={this.redirectTo}
                    disabled={coinbaseVerified}
                  >
                    <div style={{ color: "white" }}>
                      {this.state.loading
                        ? "AUTHORIZING ..."
                        : "CoinBase Wallet"}
                    </div>
                    <div>
                      <img
                        style={{ width: 60 }}
                        src={coinbase}
                        alt="Coinbase logo"
                      />
                    </div>
                  </button>

                  <form
                    className="auth-form-wrp form-edit-step3"
                    onSubmit={() => alert("finish")}
                    noValidate
                    autoComplete="off"
                  >
                    <div className="link-edit-step3">
                      <div
                        style={{ display: "block", cursor: "pointer" }}
                        onClick={() => {
                          this.setState({ defaultNumb: 4 });
                        }}
                      >
                        Skip
                      </div>
                    </div>
                  </form>
                </>
              )}
              {/* END STEP 3*/}

              {/* STEP 4 */}
              {defaultNumb === 4 && (
                <>
                  <div className="text-2fa-edit plus-margin">Finishing</div>

                  <div className="wrap-edit-step4"></div>

                  <div className="btn-wrap-step4">
                    {coinbaseVerified ? (
                      <>
                        <FontAwesomeIcon className="icon-done" icon={faCheck} />

                        <p className="desc">
                          Thank You. Click the button below to finish the setup
                        </p>
                      </>
                    ) : (
                      <>
                        <h3 className="title-icn">Some setup undone.</h3>
                        <p className="desc">Please review your setup again.</p>
                      </>
                    )}
                  </div>

                  <div className="wrap-edit-step4"></div>

                  <button
                    disabled={this.state.loading}
                    type="submit"
                    className=" btn-step4"
                    onClick={this.setIsSetup}
                  >
                    FINISH
                  </button>
                </>
              )}
              {/* END STEP 4 */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const condition = (authUser) => !!authUser;

export default compose(
  withSnackbar,
  withEmailVerification,
  withAuthorization(condition)
)(SetUp);
// export default compose(withSnackbar)(SetUp);
