import React from "react";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class TransactionHistory extends React.Component {
  state = {
    dataDummy: [
      {
        id: 1,
        type: "deposit",
      },
      {
        id: 2,
        type: "withdrawal",
      },
      {
        id: 3,
        type: "balanceincrease",
      },
    ],
  };
  render() {
    return (
      <div className="history-section">
        {this.state.dataDummy.map((value, key) => {
          if (value.type == "deposit") {
            return (
              <div key={key} className="list-box-history-transaction">
                <div>
                  <div className="icon-box-history-transaction">
                    <FontAwesomeIcon
                      icon={faArrowDown}
                      color="black"
                    ></FontAwesomeIcon>
                  </div>
                </div>
                <div>
                  <div className="title-history-transaction title-bold">
                    Deposit
                  </div>
                </div>
                <div>
                  <div className="title-history-transaction">000.0000 ETH</div>
                </div>
                <div>
                  <div className="title-history-transaction">
                    00/00/0000 @ 00:00
                  </div>
                </div>
                <div>
                  <div className="title-history-transaction">Pool : ETH</div>
                </div>
                <div>
                  <div style={{ color: "aqua" }}>View Transaction</div>
                </div>
              </div>
            );
          } else if (value.type == "withdrawal") {
            return (
              <div key={key} className="list-box-history-transaction">
                <div>
                  <div
                    className="icon-box-history-transaction"
                    style={{ backgroundColor: "red" }}
                  >
                    <FontAwesomeIcon
                      icon={faArrowUp}
                      color="black"
                    ></FontAwesomeIcon>
                  </div>
                </div>
                <div>
                  <div className="title-history-transaction title-bold">
                    Withdrawal
                  </div>
                </div>
                <div>
                  <div className="title-history-transaction">000.0000 ETH</div>
                </div>
                <div>
                  <div className="title-history-transaction">
                    00/00/0000 @ 00:00
                  </div>
                </div>
                <div>
                  <div className="title-history-transaction">Pool : ETH</div>
                </div>
                <div>
                  <div style={{ color: "aqua" }}>View Transaction</div>
                </div>
              </div>
            );
          } else if (value.type == "balanceincrease") {
            return (
              <div key={key} className="list-box-history-transaction">
                <div>
                  <div className="icon-box-history-transaction">
                    <FontAwesomeIcon
                      style={{ rotate: "45deg" }}
                      icon={faArrowUp}
                      color="black"
                    ></FontAwesomeIcon>
                  </div>
                </div>
                <div>
                  <div className="title-history-transaction title-bold">
                    Balance Increase
                  </div>
                </div>
                <div>
                  <div className="title-history-transaction">000.0000 ETH</div>
                </div>
                <div>
                  <div className="title-history-transaction">
                    00/00/0000 @ 00:00
                  </div>
                </div>
                <div>
                  <div className="title-history-transaction">Pool : ETH</div>
                </div>
                <div>
                  <div style={{ color: "black" }}>View Transaction</div>
                </div>
              </div>
            );
          }
        })}
      </div>
    );
  }
}
export default TransactionHistory;
