import * as ROUTES from "../constants/routes";
import Auth from "../views/authentication/auth";
import NotFound from "../views/authentication/not-found";
import Verify from "../views/authentication/verify";
import Dashboard from "../views/dashboards/index";
import Pools from "../views/dashboards/pools";
import PoolsDetail from "../views/dashboards/my-pools-detail";
import Setup from "../views/dashboards/setup";
import ChangePass from "../views/dashboards/change-password";
import MyApiKeys from "../views/dashboards/my-apikeys";
import MySettings from "../views/dashboards/my-settings";
import AddBot from "../views/dashboards/add-bot";
import Forgot2 from "../views/authentication/forgot2";
import Reset from "../views/authentication/reset";
import CoinBase from "../views/authentication/coinbase";
import SignIn from "../views/authentication/signin";
import SignUp from "../views/authentication/signup";
import assets from "../views/dashboards/assets";
import Transaction from "../views/dashboards/transaction";
import Referalls from "../views/dashboards/referals";

var indexRoutes = [
  { path: ROUTES.AUTH, name: "Auth", Component: Auth },
  { path: ROUTES.LANDING, name: "Home", Component: NotFound },
  { path: ROUTES.LOGIN, Component: SignIn },
  { path: ROUTES.REGISTER, Component: SignUp },
  { path: ROUTES.REGISTER_REFERRAL, Component: SignUp },
  { path: ROUTES.FORGOT2, Component: Forgot2 },
  { path: ROUTES.DASHBOARD, Component: Dashboard },
  { path: ROUTES.POOLS, Component: Pools },
  { path: ROUTES.POOLS_DETAIL, Component: PoolsDetail },
  { path: ROUTES.ASSETS, Component: assets },
  { path: ROUTES.TRANSACTION, Component: Transaction },
  { path: ROUTES.ADD_BOT, Component: AddBot },
  { path: ROUTES.SETUP, Component: Setup },
  { path: ROUTES.VERIFY, Component: Verify },
  { path: ROUTES.REFERALS, Component: Referalls },
  { path: ROUTES.RESET, Component: Reset },
  { path: ROUTES.COINBASE, Component: CoinBase },
  { path: ROUTES.MY_APIKEYS, Component: MyApiKeys },
  { path: ROUTES.MY_SETTINGS, Component: MySettings },
  { path: ROUTES.CHANGE_PASS, Component: ChangePass },
  { path: ROUTES.REFERRAL_REDIRECT, Component: NotFound },
  { path: "*", name: "UnknownRoute", Component: NotFound },
];

export default indexRoutes;
