import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import {
  AUTH_USER_SET,
  PROFILE_USER_SET,
  ACCOUNT_USER_SET,
} from "../redux/constants/";
import {
  GET_USER_BY_EMAIL,
  GET_USER_BY_ID,
  CREATE_USER,
  UPDATE_USER_BY_EMAIL
} from "../graphql";
import * as storage from "../storage";
import { graphql } from "react-apollo";

const withAuthentication = (Component) => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);

      this.props.onSetAuthUser(storage.getAuthUser());
      this.props.onSetProfileUser(storage.getProfileUser());
      this.props.onSetAccountUser(storage.getAccountUser());
    }

    componentDidMount() {
      this.listener = () => {
        let authUser = storage.getAuthUser();
        if (!authUser) {
          //window.location = ROUTES.LOGIN;
        } else {
          storage.setAuthUser(authUser);
          this.props.onSetAuthUser(authUser);
        }
      }
      this.listener();
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      return <Component {...this.props} />;
    }
  }

  const mapDispatchToProps = (dispatch) => ({
    onSetAuthUser: (authUser) => dispatch({ type: AUTH_USER_SET, authUser }),
    onSetProfileUser: (profileUser) =>
      dispatch({ type: PROFILE_USER_SET, profileUser }),
    onSetAccountUser: (accountUser) =>
      dispatch({ type: ACCOUNT_USER_SET, accountUser }),
  });

  return compose(
    graphql(CREATE_USER, {
      name: "createUser",
    }),
    graphql(GET_USER_BY_EMAIL, {
      name: "getUserByEmail",
    }),
    graphql(GET_USER_BY_ID, {
      name: "getUserByID",
    }),
    graphql(UPDATE_USER_BY_EMAIL, {
      name: "updateUser",
    }),
    connect(null, mapDispatchToProps)
  )(WithAuthentication);
};

export default withAuthentication;
