import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import {
  AUTH_USER_SET,
  PROFILE_USER_SET,
  ACCOUNT_USER_SET,
} from "../redux/constants/";
import * as storage from "../storage";

const SignOutButton = (props) => {
  const SignOut = async () => {
    props.onSetAuthUser(null);
    props.onSetProfileUser(null);
    props.onSetAccountUser(null);
    storage.signOutUser();
  };

  return (
    <div
      onClick={SignOut}
      className={`${props.className ? props.className : "sign-out"}`}
    >
      {props.children ? props.children : "Log Out"}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  onSetAuthUser: (authUser) => dispatch({ type: AUTH_USER_SET, authUser }),
  onSetProfileUser: (profileUser) =>
    dispatch({ type: PROFILE_USER_SET, profileUser }),
  onSetAccountUser: (accountUser) =>
    dispatch({ type: ACCOUNT_USER_SET, accountUser }),
});

export default compose(
  connect(null, mapDispatchToProps)
)(SignOutButton);
