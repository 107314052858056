import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, TextField } from "@material-ui/core";
import { withSnackbar } from "notistack";
import React from "react";
import { compose } from "recompose";
import logo from "../../assets/images/logo.png";
import deribitLogo from "../../assets/images/deribit-logo.png";
import binanceLogo from "../../assets/images/binance-logo.png";
import { withAuthorization, withEmailVerification } from "../../session";
import * as storage from "../../storage";
import * as ROUTES from "../../constants/routes";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
// import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const INITIAL_STATE = {
  error: "",
  errorBotName: null,
  errorApiPublicKey: null,
  errorApiPrivateKey: null,
  botName: "",
  clientId: "",
  clientSecret: "",
  loading: false,
  selectedExchange: null,
};

class AddBot extends React.Component {
  constructor() {
    super();
    this.state = { ...INITIAL_STATE };
    this.loading = this.loading.bind(this);
    this.goBack = this.goBack.bind(this); // i think you are missing this
  }

  loading = (status) => {
    this.setState({
      loading: status,
    });
  };

  goBack() {
    this.props.history.goBack();
  }

  async onChangeInput(e) {
    if (e.target.id === "clientId") {
      this.setState({ [e.target.id]: e.target.value });
    } else if (e.target.id === "clientSecret") {
      this.setState({ clientSecret: e.target.value });
    } else if (e.target.id === "botName") {
      this.setState({ [e.target.id]: e.target.value });
    }
  }

  checkStatusApi = () => {
    let { clientId, clientSecret, selectedExchange } = this.state;
    this.setState({
      loading: true,
    });
    this.props.checkExchangeAccess
      .refetch({
        client_id: clientId,
        client_secret: clientSecret,
        exchange: selectedExchange === "Deribit" ? "Deribit" : "Binance",
      })
      .then(({ data }) => {
        if (!data.check_exchange_access.isVerified) {
          this.setState({
            // error: data.check_exchange_access.message,
            loading: false,
          });
          this.props.enqueueSnackbar(data.check_exchange_access.message, {
            variant: "error",
          });
        } else {
          this.saveDataUserAccount();
        }
      });
  };

  saveDataUserAccount = () => {
    let { clientId, clientSecret, botName, selectedExchange } = this.state;
    let that = this;
    this.props
      .createUserAccount({
        variables: {
          client_id: clientId,
          secret_id: clientSecret,
          user_id: this.props.profileUser.id,
          bot_name: botName === "" ? "Bot Name" : botName,
          exchange: selectedExchange,
        },
      })
      .then(({ data }) => {
        that.props.getAccountByUserID
          .refetch({ user_id: that.props.profileUser.id })
          .then(({ data }) => {
            storage.setAccountUser(data.user_accounts);
            that.props.onSetAccountUser(data.user_accounts);
          });
        that.props.enqueueSnackbar("Account successfully added", {
          variant: "success",
        });
        this.props.history.push(ROUTES.MY_APIKEYS);
      });
  };

  render() {
    let { selectedExchange } = this.state;
    return (
      <>
        <div className="auth-wrp-outer">
          <div className="auth-wrp-inner">
            <h1 className="is-sr-only">Xtremex</h1>
            <img
              src={logo}
              className="auth-logo-title is-tablet"
              alt="Xtremex"
            />
            <div className="auth-body default">
              <div className="btn-back-page-before">
                <button className="icon-circle" onClick={this.goBack}>
                  <FontAwesomeIcon className="icon" icon={faChevronLeft} />
                </button>
                <h2 className="text">Add Bot</h2>
              </div>
              <div className="auth-wrp">
                <div className="box-default is-fullwidth is-center">
                  <h2 className="title">New Bot</h2>
                  <div className="form-group top-auto">
                    <TextField
                      error={this.state.errorBotName ? true : false}
                      id="botName"
                      type="text"
                      className="input"
                      disabled={this.state.loading}
                      value={this.state.botName}
                      label="Bot name"
                      onChange={(e) => this.onChangeInput(e)}
                      helperText={this.state.errorBotName}
                    />
                  </div>
                  <FormControl className="select-account-wrp">
                    <InputLabel id="demo-simple-select-helper-label">
                      Exchanges
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={this.state.selectedExchange}
                      onChange={(e) => {
                        this.setState({ selectedExchange: e.target.value });
                      }}
                    >
                      {/* <MenuItem value="">
                  <em>Choose exchanges</em>
                </MenuItem> */}
                      <MenuItem value="Deribit">Deribit</MenuItem>
                      <MenuItem value="Binance">Binance</MenuItem>
                    </Select>
                    {/* <FormHelperText>Some important helper text</FormHelperText> */}
                    {selectedExchange && (
                      <img
                        src={
                          selectedExchange === "Deribit"
                            ? deribitLogo
                            : binanceLogo
                        }
                        className="logo-selected-exchange"
                        alt="Logo"
                      />
                    )}
                  </FormControl>
                  {selectedExchange ? (
                    <>
                      {selectedExchange === "Deribit" && (
                        <p className="desc">
                          (Unmanaged only) Two fields for public and private
                          keys from exchange and must have "write" access on all
                          so our bot can create and manage subaccounts for them.
                          Once they submit our server will verify they are under
                          our referral link + that the api keys have the proper
                          permissions.
                        </p>
                      )}
                      {selectedExchange === "Binance" && (
                        <p className="desc">
                          Go to Dashboard > Settings > API Management.
                        </p>
                      )}
                      <form className="form-wrp" noValidate autoComplete="off">
                        <div className="form-group">
                          <TextField
                            error={this.state.errorApiPublicKey ? true : false}
                            id="clientId"
                            type="text"
                            className="input"
                            disabled={this.state.loading}
                            value={this.state.clientId}
                            label={
                              selectedExchange === "deribit"
                                ? "enter Client Id"
                                : "enter API Key"
                            }
                            onChange={(e) => this.onChangeInput(e)}
                            helperText={this.state.errorApiPublicKey}
                          />
                        </div>
                        <div className="form-group">
                          <TextField
                            error={this.state.errorApiPrivateKey ? true : false}
                            id="clientSecret"
                            type="text"
                            className="input"
                            disabled={this.state.loading}
                            value={this.state.clientSecret}
                            label={
                              selectedExchange === "deribit"
                                ? "enter Client Secret"
                                : "enter secret Key"
                            }
                            onChange={(e) => this.onChangeInput(e)}
                            helperText={this.state.errorApiPrivateKey}
                          />
                        </div>
                      </form>
                    </>
                  ) : null}

                  {/* <div>
                    {error ? (
                      <p
                        style={{
                          backgroundColor: "#1b1b1b",
                          color: "#fff",
                          padding: 10,
                          marginBottom: 10,
                        }}
                      >
                        {error}
                      </p>
                    ) : null}
                  </div> */}
                  <Button
                    disabled={
                      !selectedExchange ||
                      this.state.clientId === "" ||
                      this.state.clientSecret === "" ||
                      this.state.loading
                    }
                    type="submit"
                    className={`button is-primary btn-submit ${
                      !selectedExchange ||
                      this.state.clientId === "" ||
                      this.state.clientSecret === ""
                        ? "is-disabled"
                        : null
                    }`}
                    onClick={this.checkStatusApi}
                  >
                    {this.state.loading ? "SAVING..." : "SAVE"}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const CHECK_EXCHANGE_ACCESS = gql`
  query checkExchangeAccess(
    $client_id: String!
    $client_secret: String!
    $exchange: String!
  ) {
    check_exchange_access(
      client_id: $client_id
      client_secret: $client_secret
      exchange: $exchange
    ) {
      isVerified
      message
    }
  }
`;

const CREATE_USER_ACCOUNT = gql`
  mutation createUserAccount(
    $client_id: String!
    $secret_id: String!
    $user_id: Int
    $bot_name: String!
    $exchange: String!
  ) {
    insert_user_accounts(
      objects: {
        bot_name: $bot_name
        client_id: $client_id
        secret_id: $secret_id
        user_id: $user_id
        exchange: $exchange
      }
    ) {
      returning {
        id
        user_id
        bot_id
        client_id
        secret_id
        bot_name
        exchange
      }
    }
  }
`;

const condition = (authUser) => !!authUser;

export default withSnackbar(
  compose(
    graphql(CREATE_USER_ACCOUNT, { name: "createUserAccount" }),
    graphql(CHECK_EXCHANGE_ACCESS, { name: "checkExchangeAccess" }),
    withEmailVerification,
    withAuthorization(condition)
  )(withSnackbar(AddBot))
);
