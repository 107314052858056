import { faPlus, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Switch } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Modal from "@material-ui/core/Modal";
import gql from "graphql-tag";
// import DialogTitle from "@material-ui/core/DialogTitle";
import { withSnackbar } from "notistack";
import React from "react";
import { graphql, Subscription } from "react-apollo";
import { Link, withRouter } from "react-router-dom";
import { compose } from "recompose";
import binanceLogo from "../../assets/images/binance-logo.png";
import deribitLogo from "../../assets/images/deribit-logo.png";
import NavAccount from "../../components/nav-account";
import TopBar from "../../components/topbar";
import * as ROUTES from "../../constants/routes";
import { withAuthorization } from "../../session";
import EditBot from "./edit-bot";

class MyApikeys extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      modalDelete: false,
      modalId: null,
      checked: false,
      exchange: "",
      client_id: null,
      secret_id: null,
      secretIsShow: [],
    };
  }

  toggleHideShowSecretKey = (id) => {
    const secretIsShowArray = this.state.secretIsShow;

    const index = this.state.secretIsShow.indexOf(id);
    if (index > -1) {
      secretIsShowArray.splice(index, 1);
    } else {
      secretIsShowArray.push(id);
    }
    this.setState({ secretIsShow: secretIsShowArray });
  };

  toggleModalDelete = (e) => {
    this.setState({
      modalDelete: !this.state.modalDelete,
      modalId: e,
    });
  };

  toggleModalEdit = (e, a, c = null, s = null) => {
    this.setState({
      modal: !this.state.modal,
      modalId: e,
      exchange: a,
      client_id: c,
      secret_id: s,
    });
  };

  onChangeInput(e, data) {
    let value = e.target.value;
    data.user_accounts.map((d) => {
      if (d.id === Number(value)) {
        this.props.updateBotOn({
          variables: {
            id: d.id,
            bot_on: !d.bot_on,
          },
          optimisticResponse: true,
          update: (cache) => {
            const existingTodos = cache.readQuery({
              query: GET_USER_ACCOUNT_ALL,
            });
            const newTodos = existingTodos.user_accounts.map((t) => {
              if (t.id === d.id) {
                return { ...t, bot_on: !d.bot_on };
              } else {
                return t;
              }
            });
            cache.writeQuery({
              query: GET_USER_ACCOUNT_ALL,
              data: { user_accounts: newTodos },
            });
          },
        });
      }
      return d;
    });
  }

  deleteBotAccount = (key) => {
    this.props
      .deleteBot({
        variables: {
          id: key,
        },
        optimisticResponse: true,
        update: (cache) => {
          const existingTodos = cache.readQuery({
            query: GET_USER_ACCOUNT_ALL,
          });
          const newTodos = existingTodos.user_accounts.filter(
            (t) => t.id !== key
          );
          cache.writeQuery({
            query: GET_USER_ACCOUNT_ALL,
            data: { user_accounts: newTodos },
          });
        },
      })
      .then(({ data }) => {
        this.toggleModalDelete();
        this.props.enqueueSnackbar("Bot successfully deleted", {
          variant: "success",
        });
      });
  };

  render() {
    let { match } = this.props;
    let path = match.path;
    return (
      <>
        <TopBar />
        <div className="container-app">
          <main className="app-main main-plain with-subnav">
            <header className="header-app">
              <h2 className="title-page">My Account</h2>
              <NavAccount path={path} />
            </header>
            <Subscription
              subscription={GET_USER_ACCOUNT}
              variables={{ user_id: this.props.profileUser.id }}
            >
              {({ loading, error, data }) => {
                if (loading) return <span>Loading...</span>;
                if (error) {
                  return (
                    <div class="alert alert-danger" role="alert">
                      <b>Error:</b> {error.message}
                    </div>
                  );
                }
                return (
                  <div>
                    {data.user_accounts.map((datum) => {
                      return (
                        <div className="api-key-item" key={datum.id}>
                          <div className="content-wrp">
                            <div className="content-item">
                              <h3 className="item-title">Exchange</h3>
                              <p className="item-value">
                                <img
                                  src={
                                    datum.exchange === "Binance"
                                      ? binanceLogo
                                      : deribitLogo
                                  }
                                  className="item-logo"
                                  alt="Logo"
                                />
                                {datum.bot_name} [
                                {datum.exchange === "Binance"
                                  ? "Binance"
                                  : "Deribit"}
                                ]
                              </p>
                            </div>
                            <div className="content-item">
                              <h3 className="item-title">API Key</h3>
                              <p className="item-value">{datum.client_id}</p>
                            </div>
                            <div className="content-item">
                              <h3 className="item-title">Secret Key</h3>
                              <div className="item-value with-hide-show">
                                {this.state.secretIsShow.indexOf(datum.id) >
                                -1 ? (
                                  <div className="inner-value">
                                    {datum.secret_id}
                                  </div>
                                ) : (
                                  <div className="inner-value">
                                    ********************
                                  </div>
                                )}
                                <button
                                  type="button"
                                  className="btn-hide-show btn-reset"
                                  onClick={() =>
                                    this.toggleHideShowSecretKey(datum.id)
                                  }
                                >
                                  {this.state.secretIsShow.indexOf(datum.id) >
                                  -1 ? (
                                    <FontAwesomeIcon
                                      className="btn-icon"
                                      icon={faEyeSlash}
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      className="btn-icon"
                                      icon={faEye}
                                    />
                                  )}
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="action-wrp">
                            <Button
                              className="item-action btn-action button is-outline is-danger"
                              onClick={() => this.toggleModalDelete(datum.id)}
                            >
                              Delete Bot
                            </Button>
                            <Button
                              className="item-action btn-action button is-outline is-secondary"
                              onClick={() =>
                                this.toggleModalEdit(
                                  datum.id,
                                  datum.exchange,
                                  datum.client_id,
                                  datum.secret_id
                                )
                              }
                            >
                              Edit Keys
                            </Button>
                            <p className="item-action is-center">
                              <p className="action-title">
                                {datum.bot_on === false ? "Offline" : "Online"}
                              </p>
                              <Switch
                                value={datum.id}
                                checked={datum.bot_on}
                                className="switch-action"
                                onChange={(e) => this.onChangeInput(e, data)}
                                color="primary"
                              />
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              }}
            </Subscription>

            <Link className="btn-clear btn-floating" to={ROUTES.ADD_BOT}>
              <FontAwesomeIcon className="item-icon" icon={faPlus} />
              <span className="item-text">Bot</span>
            </Link>
          </main>
        </div>
        <Modal
          open={this.state.modal}
          className="modal show"
          onClose={() => this.toggleModalEdit()}
          aria-labelledby="Pas Result"
          aria-describedby="Past Result Data"
        >
          <>
            <button
              type="button"
              className="close btn-close-modal"
              onClick={() => this.toggleModalEdit()}
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <div className="modal-dialog">
              <div
                className="modal-content"
                style={{ backgroundColor: "transparent" }}
              >
                <EditBot
                  {...this.props}
                  bot_id={this.state.modalId}
                  exchange={this.state.exchange}
                  client_id={this.state.client_id}
                  secret_id={this.state.secret_id}
                  toggleModal={() => this.toggleModalEdit()}
                />
              </div>
            </div>
          </>
        </Modal>
        <Dialog
          open={this.state.modalDelete}
          onClose={() => this.toggleModalDelete()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {/* <DialogTitle id="alert-dialog-title">
            Are you sure you want to delete this bot?
          </DialogTitle> */}
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this bot?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.toggleModalDelete()} color="primary">
              No
            </Button>
            <Button
              onClick={() => this.deleteBotAccount(this.state.modalId)}
              color="primary"
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

const GET_USER_QUERY = gql`
  query ($email: String!) {
    users(where: { email: { _eq: $email } }) {
      email
      telegram
      referral
      team
      access
      payment
    }
  }
`;

const CHECK_EXCHANGE_ACCESS = gql`
  query checkExchangeAccess(
    $client_id: String!
    $client_secret: String!
    $exchange: String!
  ) {
    check_exchange_access(
      client_id: $client_id
      client_secret: $client_secret
      exchange: $exchange
    ) {
      isVerified
      message
    }
  }
`;

const GET_USER_ACCOUNT = gql`
  subscription getUserAccount($user_id: Int) {
    user_accounts(
      where: { user_id: { _eq: $user_id } }
      order_by: { id: asc }
    ) {
      id
      user_id
      client_id
      secret_id
      bot_on
      bot_name
      exchange
    }
  }
`;

const GET_USER_ACCOUNT_ALL = gql`
  query MyQuery {
    user_accounts {
      id
      user_id
      bot_on
      client_id
      secret_id
    }
  }
`;

const UPDATE_USER_ACCOUNT = gql`
  mutation Mutation($id: Int!, $client_id: String!, $client_secret: String!) {
    update_user_accounts(
      where: { id: { _eq: $id } }
      _set: { client_id: $client_id, secret_id: $client_secret }
    ) {
      returning {
        id
        user_id
        client_id
        secret_id
      }
    }
  }
`;

const UPDATE_BOT_ON = gql`
  mutation MyMutation($bot_on: Boolean, $id: Int!) {
    update_user_accounts(
      where: { id: { _eq: $id } }
      _set: { bot_on: $bot_on }
    ) {
      affected_rows
      returning {
        bot_on
        user_id
      }
    }
  }
`;

const DELETE_BOT = gql`
  mutation removeBot($id: Int!) {
    delete_user_accounts(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

const condition = (authUser) => !!authUser;

export default withSnackbar(
  compose(
    graphql(GET_USER_QUERY, { name: "getUserbyEmail" }),
    graphql(GET_USER_ACCOUNT_ALL, { name: "getAccount" }),
    graphql(UPDATE_BOT_ON, { name: "updateBotOn" }),
    graphql(CHECK_EXCHANGE_ACCESS, { name: "checkExchangeAccess" }),
    graphql(UPDATE_USER_ACCOUNT, { name: "updateAccountUser" }),
    graphql(DELETE_BOT, { name: "deleteBot" }),
    withAuthorization(condition)
  )(withRouter(MyApikeys))
);
