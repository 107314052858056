import React, { useEffect, useState, useRef } from "react";

const Dropdown = ({
  label,
  parentClassName = "thedropdown",
  childrenClassName = "thedropdown-toggle",
  menuClassName = "thedropdown-menu",
  itemClassName = "thedropdown-item",
  position = "right-down",
  options,
}) => {
  const node = useRef();

  const [open, setOpen] = useState(false);

  const handleClickOutside = (e) => {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open]);

  return (
    <>
      <div ref={node} className={parentClassName}>
        <button onClick={(e) => setOpen(!open)} className={childrenClassName}>
          {label}
        </button>
        {open && (
          <div className={`${menuClassName} ${position}`}>
            {options.map((opt, i) => (
              <div className={itemClassName} key={i} onClick={opt.onClick}>
                {opt.label}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default Dropdown;
