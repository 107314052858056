import React from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { connect } from "react-redux";
import * as ROUTES from "../constants/routes";

const withRedirectIfAuth = (condition) => (Component) => {
  class WithRedirectIfAuth extends React.Component {
    componentDidMount() {
      this.listener = (authUser) => {
          if (condition(authUser)) {
            window.location = ROUTES.DASHBOARD;
            // window.location = ROUTES.VERIFY_EMAIL;
          }
        }
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      return <Component {...this.props} />;
    }
  }

  const mapStateToProps = (state) => ({
    authUser: state.sessionState.authUser,
    profileUser: state.sessionState.profileUser,
  });

  return compose(
    withRouter,
    connect(mapStateToProps)
  )(WithRedirectIfAuth);
};

export default withRedirectIfAuth;
