import React from "react";
import { compose } from "recompose";
import { withAuthorization, withEmailVerification } from "../../session";
import TopBar from "../../components/topbar";
import { graphql } from "react-apollo";

import { GET_USER_BY_EMAIL } from "../../graphql";
import AssetsTemp from "./assetsTemp";

class Assets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  onSelect = (tabIndex) => {
    this.setState({
      selectIndex: tabIndex,
    });
  };

  render() {
    return (
      <>
        <TopBar path={this.props.location.pathname} />
        <main
          className={`app-main${this.state.botAccountIsEmpty ? " fcc" : ""}`}
        >
          <AssetsTemp />
        </main>
      </>
    );
  }
}

const condition = (authUser) => !!authUser;

export default compose(
  graphql(GET_USER_BY_EMAIL, { name: "getUserByEmail" }),
  withEmailVerification,
  withAuthorization(condition)
)(Assets);
