import {createStore, applyMiddleware} from "redux";
import {createLogger} from "redux-logger";
import reducers from "./reducers";

const logger = createLogger();

const middlewares = [logger];

const store = createStore(reducers, applyMiddleware(...middlewares));

export default store;
