import { TextField } from "@material-ui/core";
import { withSnackbar } from "notistack";
import React from "react";
import { compose } from "recompose";
import { ApolloClient, InMemoryCache } from "@apollo/client";
import * as ROUTES from "../../constants/routes";
import { Link } from "react-router-dom";

import { CREATE_USER, GET_REFERRAL_ID } from "../../graphql";
import "../../assets/scss/style2.css";
import logo from "../../assets/images/xtremex-logo-150.jpg";

const client = new ApolloClient({
  uri: "https://api.xtremex.io/",
  cache: new InMemoryCache().restore(window.__APOLLO_STATE__),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
      errorPolicy: "ignore",
    },
    query: {
      fetchPolicy: "cache-first",
      errorPolicy: "all",
    },
    subscribe: {
      fetchPolicy: "cache-first",
      errorPolicy: "ignore",
    },
  },
});
const INITIAL_STATE = {
  email: "",
  password: "",
  confirmPassword: "",
  code: "",
  error: "",
  errorEmail: "",
  errorPassword: "",
  errorConfirmPassword: "",
  errorCode: "",
  loading: false,
  twoFacAuth: false,
  datas: [],
  steps: [1, 2, 3, 4],
  defaultStep: 1,
  referr_id: null,
  referr_by: null,
  referrEmail: null,
  showReferr: false,
};

let url = window.location.search.toString();
var referralCode = url.slice(3, 11);

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
    this.email = this.email.bind(this);
    this.password = this.password.bind(this);
    this.code = this.code.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.loading = this.loading.bind(this);
  }

  componentDidMount() {
    this.getReferBy();
  }

  getReferBy() {
    if (referralCode != null || referralCode != undefined) {
      client
        .query({
          variables: { refId: referralCode },
          query: GET_REFERRAL_ID,
        })
        .then((result) => {
          if (result) {
            let x = result.data.x_users;
            if (x.length > 0) {
              this.setState({
                referr_id: referralCode,
                referrEmail: x[0].email,
                referr_by: x[0].name,
                showReferr: true,
              });
            }
          }
        });
    }
  }

  email = (event) => {
    this.setState({ email: event.target.value.toString().trim() }, () => {
      this.validateEmail();
    });
  };

  validateEmail = () => {
    let regxp =
      /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (this.state.email.trim().length < 1) {
      this.setState({
        errorEmail: "Email is required.",
      });
    } else if (!regxp.test(this.state.email.trim())) {
      this.setState({
        errorEmail: "Email format is invalid.",
      });
    } else {
      this.setState({
        errorEmail: "",
      });
      return true;
    }
    return false;
  };

  password = (event) => {
    this.setState({ password: event.target.value.toString().trim() }, () => {
      this.validatePassword();
    });
  };

  confirmPassword = (event) => {
    this.setState(
      { confirmPassword: event.target.value.toString().trim() },
      () => {
        this.validateConfirmPassword();
      }
    );
  };

  code = (value) => {
    this.setState({ code: value.toString().trim() }, () => {
      this.validateCode();
    });
  };

  validatePassword = () => {
    if (this.state.password.trim().length < 1) {
      this.setState({
        errorPassword: "Password is required.",
      });
    } else if (this.state.password.trim().length < 8) {
      this.setState({
        errorPassword: "Password must be at least 8 characters.",
      });
    } else {
      this.setState({
        errorPassword: "",
      });
      return true;
    }
    return false;
  };

  validateConfirmPassword = () => {
    if (this.state.confirmPassword.trim().length < 1) {
      this.setState({
        errorConfirmPassword: "Password is required.",
      });
    } else if (this.state.confirmPassword.trim().length < 8) {
      this.setState({
        errorConfirmPassword: "Password must be at least 8 characters.",
      });
    } else if (this.state.confirmPassword !== this.state.password) {
      this.setState({
        errorConfirmPassword: "Passwort doesn't match",
      });
    } else {
      this.setState({
        errorConfirmPassword: "",
      });
      return true;
    }
    return false;
  };

  validateCode = () => {
    if (this.state.code.trim().length < 1) {
      this.setState({
        errorCode: "Code is required.",
      });
    } else if (this.state.code.trim().length < 6) {
      this.setState({
        errorCode: "Code must be at least 6 characters.",
      });
    } else {
      this.setState({
        errorCode: "",
      });
      return true;
    }
    return false;
  };

  validate = () => {
    return (
      this.validateEmail() &&
      this.validatePassword() &&
      this.validateConfirmPassword()
    );
  };

  validate2 = () => {
    return this.validateCode();
  };

  loading = (status) => {
    this.setState({
      loading: status,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (!this.validate()) {
      return false;
    }
    this.loading(true);
    const { email, password, referr_id } = this.state;
    client
      .mutate({
        variables: {
          email: email,
          password: password,
          referal: referr_id !== null ? referr_id : "",
        },
        mutation: CREATE_USER,
      })
      .then((result) => {
        this.loading(false);

        if (result.data.create_user_xtremex.status === true) {
          this.props.enqueueSnackbar(result.data.create_user_xtremex.message, {
            persist: true,
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          });
        } else {
          this.loading(false);
          this.props.enqueueSnackbar(result.data.create_user_xtremex.message, {
            persist: true,
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          });
        }
      })
      .catch((er) => {
        this.loading(false);
        this.props.enqueueSnackbar(er, {
          persist: true,
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        });
      });
  };
  render() {
    return (
      <div className="login-container-edit">
        <div className="content-wrapper-edit">
          <div className="auth-wrp box-content-edit">
            <div className="box-single">
              <div className={`button-section`}>
                <div className={"capsule-switch-wrp login"}>
                  <Link className={`capsule-item`} to={ROUTES.LOGIN}>
                    Login
                  </Link>
                  <Link className={`capsule-item is-active`} to={ROUTES.REGISTER}>
                    Register
                  </Link>
                </div>
              </div>
              <div className={`image-section`}>
                <img
                  src={logo}
                  className="auth-logo-title"
                  alt="Xtremex"
                />
              </div>
              {this.state.showReferr === true ? (
                <>
                  <div className="referr-section">
                    You were referred by{" "}
                    {this.state.referr_by == null
                      ? this.state.referrEmail
                      : this.state.referr_by}
                  </div>
                </>
              ) : null}
              <div className={`form-section`}>
                <form
                  className="auth-form-wrp formsignup-edit"
                  onSubmit={this.handleSubmit}
                  noValidate
                  autoComplete="off"
                >
                  <div className="form-group">
                    <TextField
                      error={this.state.errorEmail ? true : false}
                      type="email"
                      className="input is-fullwidth"
                      disabled={this.state.loading}
                      value={this.state.email}
                      label="Your email"
                      onChange={this.email}
                      helperText={this.state.errorEmail}
                    />
                  </div>

                  <div className="form-group">
                    <TextField
                      error={this.state.errorPassword ? true : false}
                      id="password"
                      type="password"
                      className="input is-fullwidth"
                      disabled={this.state.loading}
                      value={this.state.password}
                      label="Password"
                      onChange={this.password}
                      helperText={this.state.errorPassword}
                    />
                  </div>

                  <div className="form-group">
                    <TextField
                      error={this.state.errorConfirmPassword ? true : false}
                      type="password"
                      className="input is-fullwidth"
                      disabled={this.state.loading}
                      value={this.state.confirmPassword}
                      label="Confirm password"
                      onChange={this.confirmPassword}
                      helperText={this.state.errorConfirmPassword}
                    />
                  </div>

                  <button
                    disabled={this.state.loading}
                    type="submit"
                    className="btn-signup-edit"
                  >
                    {this.state.loading ? "LOADING..." : "REGISTER"}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default compose(withSnackbar)(Register);
