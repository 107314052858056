import { combineReducers } from 'redux'
import sessionReducer from './sessions'
import userReducer from './users'

const reducers = combineReducers({
  sessionState: sessionReducer,
  userState: userReducer
})

export default reducers
