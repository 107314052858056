// import PropTypes from "prop-types";
import React from "react";
import { withRouter } from "react-router-dom";
import { AnimatedSwitch } from "react-router-transition";
import logo from "../../assets/images/xtremex-logo-150.jpg";
import Forgot from "./forgot";

const Forgot2 = (props) => {
  return (
    <>
      <div className="auth-wrp-outer">
        <div className="auth-wrp-inner">
          <h1 className="is-sr-only">Xtremex</h1>
          <img src={logo} className="auth-logo-title" alt="Xtremex" />
          <div className={`auth-body login`}>
            <AnimatedSwitch
              atEnter={{ opacity: 0 }}
              atLeave={{ opacity: 0 }}
              atActive={{ opacity: 1 }}
              className="auth-switch-wrp"
            >
            <Forgot />
            </AnimatedSwitch>
            <div className="auth-footer-text">
              <div
                className="text-link"
                onClick={() => props.history.push("/login")}
              >
                Login to your account
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(Forgot2);
