import SecureStorage from "secure-web-storage";
const CryptoJS = require("crypto-js");

const SECRET_KEY = "weryHSEW47234290HW3749DHDA&123&UwbSGDT";
const AUTH_USER = "authUser";
const PROFILE_USER = "profileUser";
const ACCOUNT_USER = "accountUser";

/*
const secureStorage = new SecureStorage(localStorage, {
  hash: function hash (key) {
    key = CryptoJS.SHA256(key, SECRET_KEY)

    return key.toString()
  },
  encrypt: function encrypt (data) {
    data = CryptoJS.AES.encrypt(data, SECRET_KEY)

    data = data.toString()

    return data
  },
  decrypt: function decrypt (data) {
    data = CryptoJS.AES.decrypt(data, SECRET_KEY)

    data = data.toString(CryptoJS.enc.Utf8)

    return data
  }
})
*/

const secureSessionStorage = new SecureStorage(sessionStorage, {
  hash: function hash(key) {
    key = CryptoJS.SHA256(key, SECRET_KEY);

    return key.toString();
  },
  encrypt: function encrypt(data) {
    data = CryptoJS.AES.encrypt(data, SECRET_KEY);

    data = data.toString();

    return data;
  },
  decrypt: function decrypt(data) {
    data = CryptoJS.AES.decrypt(data, SECRET_KEY);

    data = data.toString(CryptoJS.enc.Utf8);

    return data;
  }
});

export const setAuthUser = authData => {
  secureSessionStorage.setItem(AUTH_USER, authData);
};

export const setProfileUser = profileData => {
  secureSessionStorage.setItem(PROFILE_USER, profileData);
};

export const setAccountUser = accountData => {
  secureSessionStorage.setItem(ACCOUNT_USER, accountData);
};

export const getAuthUser = () => {
  return secureSessionStorage.getItem(AUTH_USER);
};

export const getProfileUser = () => {
  return secureSessionStorage.getItem(PROFILE_USER);
};

export const getAccountUser = () => {
  return secureSessionStorage.getItem(ACCOUNT_USER);
};

export const signOutUser = () => {
  secureSessionStorage.removeItem(AUTH_USER);
  secureSessionStorage.removeItem(PROFILE_USER);
  secureSessionStorage.removeItem(ACCOUNT_USER);
};
