import { AUTH_USER_SET, PROFILE_USER_SET, ACCOUNT_USER_SET } from '../constants/';

const INITIAL_STATE = {
  authUser: null,
  profileUser: null,
  accountUser: null,
};

const applySetAuthUser = (state, action) => ({
  ...state,
  authUser: action.authUser,
});

const applySetProfileUser = (state, action) => ({
  ...state,
  profileUser: action.profileUser,
});

const applySetAccountUser = (state, action) => ({
  ...state,
  accountUser: action.accountUser,
});

function sessionReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case AUTH_USER_SET: {
      return applySetAuthUser(state, action);
    }
    case PROFILE_USER_SET: {
      return applySetProfileUser(state, action);
    }
    case ACCOUNT_USER_SET: {
      return applySetAccountUser(state, action);
    }
    default:
      return state;
  }
}

export default sessionReducer;