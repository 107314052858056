import React from "react";
import { compose } from "recompose";
import { withAuthorization, withEmailVerification } from "../../session";

import * as storage from "../../storage";
import TopBar from "../../components/topbar";
import { graphql } from "react-apollo";
import MyPools from "./my-pools";
import Chart from "./chartTemp";

import { GET_USER_BY_EMAIL } from "../../graphql";
import TransactionHistory from "./transaction-history";

let authUser = storage.getAuthUser() != null ? storage.getAuthUser() : "";

class Transaction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      botAccountIsEmpty: false,
      tabIndex: 0,
      id: 0,
    };
  }

  componentDidMount() {
    if (this.props.accountUser) {
      this.setState({
        botAccountIsEmpty: this.props.accountUser.length > 0 ? false : true,
      });
    }
    this.props.getUserByEmail
      .refetch({ email: this.props.authUser.email })
      .then(({ data }) => {
        this.setState({
          id: data.x_users[0].id,
        });
      });
  }

  onSelect = (tabIndex) => {
    this.setState({
      selectIndex: tabIndex,
    });
  };

  render() {
    return (
      <>
        <TopBar path={this.props.location.pathname} />
        <main
          className={`app-main${this.state.botAccountIsEmpty ? " fcc" : ""}`}
        >
          <header className="header-app">
            <h2 className="title-page">Transaction History</h2>
          </header>
          <div>
            <TransactionHistory />
          </div>
        </main>
      </>
    );
  }
}

const condition = (authUser) => !!authUser;

export default compose(
  graphql(GET_USER_BY_EMAIL, { name: "getUserByEmail" }),
  withEmailVerification,
  withAuthorization(condition)
)(Transaction);
