import React, { useEffect } from "react";

import logo from "../../assets/images/xtremex-logo.jpg";
import { withRedirectIfAuth } from "../../session";
import * as ROUTES from "../../constants/routes";

const NotFound = () => {
  let url = window.location.search.toString();
  const validRerral = Boolean(
    window.location.search.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
  );
  const referralCode = url;
  useEffect(() => {
    if (referralCode !== "" || validRerral) {
      Go_To();
    }
  }, [referralCode]);

  const Go_To = () => {
    setTimeout(() => {
      window.location = ROUTES.REGISTER_REFERRAL;
    }, 1000);
  };
  return (
    <div className="flex-center-center" style={{ width: "100vw" }}>
      <div className="not-found-box">
        <div className="not-found-body text-center">
          <img src={logo} alt="error-img" />
          <p className="not-found-text is-center">
            <a href="https://t.me/ebbpool">Follow us on telegram</a>
            {/* 
              <a href="/auth/signup">
                <strong>Click Here</strong> to Get Started
              </a>
              */}
          </p>
        </div>
      </div>
    </div>
  );
};

const condition = (authUser) => !!authUser;

export default withRedirectIfAuth(condition)(NotFound);
