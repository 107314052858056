let referral_code = window.location.search.toString();

export const AUTH_NAME = "auth";
export const SIGN_UP_NAME = "register";
export const SIGN_IN_NAME = "login";
export const FORGOT_NAME = "forgot";
export const LANDING_NAME = "";
export const DASHBOARD_NAME = "dashboard";
export const ASSETS_NAME = "assets";
export const POOLS_NAME = "pools";
export const POOLS_DETAIL_NAME = "detail";
export const TRANSACTION_NAME = "transaction";
export const VERIFY_NAME = "verify";
export const SETUP_NAME = "setup";
export const RESET_NAME = "reset";
export const COINBASE_NAME = "coinbase";
export const CHANGE_PASS_NAME = "changepassword";
export const MY_APIKEYS_NAME = "apikeys";
export const MY_SETTINGS_NAME = "settings";
export const ADD_BOT_NAME = "addbot";
export const REFERALS_NAME = "referral";

export const AUTH_BASE = "/" + AUTH_NAME;
export const AUTH = AUTH_BASE + "/:param";
export const LOGIN = "/" + SIGN_IN_NAME;
export const REGISTER = "/" + SIGN_UP_NAME;
export const REGISTER_REFERRAL = "/" + SIGN_UP_NAME + referral_code;
export const REFERRAL_REDIRECT = "/" + referral_code;
export const SIGN_UP = AUTH_BASE + "/" + SIGN_UP_NAME;
export const SIGN_IN = AUTH_BASE + "/" + SIGN_IN_NAME;
export const FORGOT = "/" + FORGOT_NAME;
export const FORGOT2 = "/" + FORGOT_NAME;
export const LANDING = "/" + LANDING_NAME;
export const DASHBOARD = "/" + DASHBOARD_NAME;
export const POOLS = "/" + POOLS_NAME;
export const POOLS_DETAIL = "/pool/" + POOLS_DETAIL_NAME;
export const ASSETS = "/" + ASSETS_NAME;
export const TRANSACTION = "/" + TRANSACTION_NAME;
export const VERIFY = "/" + VERIFY_NAME;
export const SETUP = "/" + SETUP_NAME;
export const RESET = "/" + RESET_NAME;
export const COINBASE = "/" + COINBASE_NAME + "/callback";
export const CHANGE_PASS = "/" + CHANGE_PASS_NAME;
export const MY_APIKEYS = "/" + MY_APIKEYS_NAME;
export const MY_SETTINGS = "/" + MY_SETTINGS_NAME;
export const ADD_BOT = "/" + ADD_BOT_NAME;
export const REFERALS = "/" + REFERALS_NAME;
