import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import logo from "../assets/images/logo.png";
import { withFirebase } from "../firebase";
import SignOutButton from "../components/signout";
import { Button } from "@material-ui/core";

const needsEmailVerification = (authUser) =>
  authUser &&
  authUser.auth &&
  authUser.auth.authUser &&
  !authUser.auth.authUser.emailVerified &&
  authUser.auth.authUser.providerData
    .map((provider) => provider.providerId)
    .includes("password");

const withEmailVerification = (Component) => {
  class WithEmailVerification extends React.Component {
    constructor(props) {
      super(props);

      this.state = { loading: false, isSent: false };
    }

    loading = (status) => {
      this.setState({
        loading: status,
      });
    };

    onSendEmailVerification = () => {
      this.loading(true);
      this.props.firebase
        .doSendEmailVerification()
        .then(() => this.setState({ isSent: true, loading: false }));
    };

    render() {
      return needsEmailVerification(this.props.authUser) ? (
        <>
          <div className="auth-wrp-outer">
            <div className="auth-wrp-inner">
              <h1 className="is-sr-only">Xtremex</h1>
              <img src={logo} className="auth-logo-title" alt="Xtremex" />
              <div className="auth-body login">
                <div className="auth-wrp">
                  <div className="box-email-confirmation">
                    {this.state.isSent ? (
                      <p className="auth-desc">
                        Email confirmation sent. Check your email for a
                        confirmation email. Refresh this page once if you have
                        been confirmed your email.
                      </p>
                    ) : (
                      <p className="auth-desc">
                        Your account is have not been verified. Check your email
                        for a confirmation email or resend another confirmation
                        email.
                      </p>
                    )}

                    <Button
                      disabled={this.state.loading}
                      onClick={this.onSendEmailVerification}
                      className="button btn-block is-secondary btn-resend-confirmation"
                    >
                      {this.state.loading
                        ? "Resending..."
                        : "Resend Confirmation"}
                    </Button>
                    <div className="button-separator">or</div>
                    <SignOutButton className="button btn-block is-outline is-secondary" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Component {...this.props} />
      );
    }
  }

  const mapStateToProps = (state) => ({
    authUser: state.sessionState.authUser,
    profileUser: state.sessionState.profileUser,
  });

  return compose(withFirebase, connect(mapStateToProps))(WithEmailVerification);
};

export default withEmailVerification;
