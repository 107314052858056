import React from "react";
import * as storage from "../../storage";
import { graphql } from "react-apollo";
import { Link, withRouter } from "react-router-dom";
import { compose } from "recompose";
import { withSnackbar } from "notistack";
import { Button, Modal, Backdrop, Fade } from "@material-ui/core";
import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import TelegramLoginButton from "react-telegram-login";
import { faTelegramPlane } from "@fortawesome/free-brands-svg-icons";
import gql from "graphql-tag";
import * as ROUTES from "../../constants/routes";
import {
  GET_QR_CODE,
  SUBMIT_TWO_FACTOR,
  UPDATE_PROFILE_USER,
  DELETE_TWO_FACTOR,
  UPDATE_EMAILVER_USER,
} from "../../graphql";
// import NavAccount from "../../components/nav-account";
import TopBar from "../../components/topbar";
import { withAuthorization } from "../../session";
import { faPen, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

let authUser = storage.getAuthUser() != null ? storage.getAuthUser() : "";

const authLink = createHttpLink({
  uri: "https://api.xtremex.io/",
  headers: {
    authorization: `Bearer ${authUser.token}`,
  },
});

const client = new ApolloClient({
  link: authLink,
  cache: new InMemoryCache().restore(window.__APOLLO_STATE__),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "network-only",
      errorPolicy: "ignore",
    },
    query: {
      fetchPolicy: "network-only",
      errorPolicy: "all",
    },
    subscribe: {
      fetchPolicy: "network-only",
      errorPolicy: "ignore",
    },
  },
});

class MySettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      code: "",
      message: "",
      disableEmail: true,
      email: "",
    };
  }

  componentDidMount() {
    let itsAccount = this.props.authUser;
    this.getQrCode();
    this.setState({
      email: itsAccount.email,
      is_2fa: itsAccount.is_2fa,
      setup_account: itsAccount.is_setup_account,
      telegram: itsAccount.telegram,
      key_2: itsAccount.key2fa,
      setModal: false,
      loading: false,
      code: "",
    });
  }

  onClickButton = () => {
    this.setState({
      setModal: true,
    });
  };
  disableTwoFactor = () => {
    client
      .mutate({
        variables: { email: authUser.email, token: authUser.token },
        mutation: DELETE_TWO_FACTOR,
      })
      .then((result) => {
        if (result.data.set_2fa_status.message === "Successful") {
          const data = {
            id: authUser.id,
            email: authUser.email,
            name: authUser.name,
            telegram: authUser.telegram,
            is_2fa: false,
            is_setup_account: authUser.is_setup_account,
            token: authUser.token,
            key_2fa: authUser.key_2fa,
          };
          storage.setAuthUser(data);
          this.setState({
            is_2fa: false,
          });
          this.props.enqueueSnackbar("Successful", {
            persist: true,
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          });
        }
      })
      .catch((er) => {});
  };
  closeModal = () => {
    this.setState({
      setModal: false,
    });
  };

  getQrCode = () => {
    client
      .mutate({
        variables: { email: authUser.email, token: authUser.token },
        mutation: GET_QR_CODE,
      })
      .then((result) => {
        this.setState({
          imageUrl: result.data.gen_2fa_xtremex.data.qrcode_image,
          secretCode: result.data.gen_2fa_xtremex.data.code_secret,
        });
      })
      .catch((er) => {});
  };

  submitTwoFactor = () => {
    client
      .mutate({
        variables: {
          email: this.state.email,
          code: this.state.code,
          secret: this.state.secretCode,
          token: authUser.token,
        },
        mutation: SUBMIT_TWO_FACTOR,
      })
      .then((result) => {
        if (result.data.set_2fa_status.status !== false) {
          const data = {
            id: authUser.id,
            email: authUser.email,
            name: authUser.name,
            telegram: authUser.telegram,
            is_2fa: true,
            is_setup_account: authUser.is_setup_account,
            token: authUser.token,
            key_2fa: authUser.key_2fa,
          };
          storage.setAuthUser(data);
          this.setState({
            is_2fa: true,
            setModal: false,
          });
          this.props.enqueueSnackbar("Successful", {
            persist: true,
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          });
        } else {
          this.setState({ message: result.data.set_2fa_status.message });
        }
      })
      .catch((er) => {
        this.setState({ message: "Eror Authentication Code" });
        this.componentDidMount();
      });
  };
  async onChangeInput(e) {
    this.setState({ [e.target.id]: e.target.value });
  }
  clickEmail = () => {
    const { disableEmail } = this.state;
    if (disableEmail) {
      this.setState({ disableEmail: false });
    } else {
      this.setState({ disableEmail: true });

      client
        .mutate({
          variables: { userId: authUser.id, email: this.state.email },
          mutation: UPDATE_EMAILVER_USER,
        })
        .then((result) => {
          if (result.data.update_emailver_xtremex.status === true) {
            this.setState({
              loading: false,
            });
            this.props.enqueueSnackbar(
              "Change email success, need to verify your email",
              {
                persist: true,
                variant: "success",
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center",
                },
              }
            );
            storage.signOutUser();
            window.location = ROUTES.LOGIN;
          }
        })
        .catch((er) => {
          this.setState({
            loading: false,
          });
        });
    }
  };
  render() {
    return (
      <>
        <TopBar />
        <div className="container-app">
          <main className="app-main-settings main-plain">
            <header className="header-app">
              <h2 className="title-page">Settings</h2>
            </header>
            {/* EMAIL OR TELEGRAM  */}
            <div className="content-app">
              <from className="from-group-settings">
                <div className="group-items-settings">
                  <div className="path-input">
                    <h3 className="text-sub-title">Email Address :</h3>
                    <div style={{ display: "flex" }}>
                      <input
                        className="input-settings"
                        style={{
                          borderTopRightRadius: "0px",
                          borderBottomRightRadius: "0px",
                        }}
                        type="email"
                        id="email" // disabled={this.state.loading}
                        disabled={this.state.disableEmail}
                        value={this.state.email}
                        label="Email"
                        onChange={(e) => this.onChangeInput(e)}
                        // helperText={this.state.errorApiPublicKey}
                      />
                      <div className="icon-wrapper-email">
                        <FontAwesomeIcon
                          className="icon"
                          color="grey"
                          icon={
                            this.state.disableEmail === true ? faPen : faSave
                          }
                          onClick={() => this.clickEmail()}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="path-input">
                    <h3 className="text-sub-title">Telegram account</h3>
                    <div className="group-item">
                      {this.state.telegram ? (
                        <button
                          className="button is-telegram"
                          onClick={() => this.removeDataTelegram()}
                        >
                          <FontAwesomeIcon
                            className="icon-telegram"
                            icon={faTelegramPlane}
                          />
                          Disconnect as {this.state.telegram}
                        </button>
                      ) : (
                        <>
                          <TelegramLoginButton
                            botName="xtremex_bot"
                            usePic={false}
                            dataOnauth={(user) => {
                              this.saveDataTelegram(user);
                            }}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </from>
            </div>

            {/* SECURITY */}
            <div className="content-app-2">
              <div className="title-account">
                <h2 className="text-title-account">Security</h2>
              </div>
              <div className="password-security">
                <div className="password">
                  <h2 className="text-sub-title">Password</h2>
                  <p className="text-intruction">
                    The Password is used to login and change sensitive settings
                  </p>
                  <Link
                    style={{
                      borderRadius: "20px",
                      textAlign: "center",
                    }}
                    className="btn-account btn button is-primary btn-action MuiButtonBase-root MuiButton-root MuiButton-text"
                    to={ROUTES.CHANGE_PASS}
                  >
                    CHANGE PASSWORD
                  </Link>
                </div>
              </div>
              <div className="Factory">
                <h2 className="text-sub-title">2 Factor Authentication</h2>
                <p style={{ fontSize: "17px", color: "gray" }}>
                  Two Factor Authentication (2FA) prevents unauthorized access
                  from other divices , even if your username and password are
                  compromised{" "}
                </p>
                <p className="text-intruction">
                  If you lose your access to your authenticator , it will take 7
                  days to access your account without the backup code
                </p>
                <Button
                  className="btn button is-primary btn-action"
                  style={{ borderRadius: "20px" }}
                  onClick={
                    this.state.is_2fa
                      ? () => this.disableTwoFactor()
                      : () => this.onClickButton()
                  }
                >
                  {this.state.is_2fa ? "Disable 2FA" : "Enable 2FA"}
                </Button>
              </div>
            </div>
          </main>
          {/* MODAL */}
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            open={this.state.setModal}
            onClose={this.closeModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 100,
            }}
          >
            <Fade in={this.state.setModal}>
              <div className="paper_settings">
                <h2 className="title-Modal">Register (2FA)</h2>
                <div className="paper_">
                  <div className="inPaper">
                    <img alt="QR code" src={this.state.imageUrl} />
                  </div>
                  <div className="inPaper">
                    <h3
                      className="text-sub-title-modal"
                      style={{ color: "white" }}
                    >
                      Secret Code :
                    </h3>
                    <input
                      id="secret_code"
                      type="input:"
                      className="input-settings-modal"
                      value={this.state.secretCode}
                      label="Code"
                      onChange={(e) => this.onChangeInput(e)}
                    />
                    <h3
                      className="text-sub-title-modal"
                      style={{ color: "white" }}
                    >
                      Authentication Code :
                    </h3>
                    <input
                      id="code"
                      type="password"
                      className="input-settings-modal"
                      label="Code"
                      onChange={(e) => this.onChangeInput(e)}
                    />
                    <p style={{ color: "red" }}>{this.state.message}</p>
                    <Button
                      className="button-modal btn button is-primary btn-action"
                      style={{
                        borderRadius: "10px",
                        marginTop: "20px",
                        width: "100%",
                      }}
                      onClick={() => this.submitTwoFactor()}
                    >
                      Enable 2FA
                    </Button>
                  </div>
                </div>
              </div>
            </Fade>
          </Modal>
        </div>
      </>
    );
  }
}

const GET_USER_QUERY = gql`
  query ($email: String!) {
    users(where: { email: { _eq: $email } }) {
      email
      telegram
      referral
      team
      access
      payment
    }
  }
`;

const condition = (authUser) => !!authUser;

export default withSnackbar(
  compose(
    graphql(GET_USER_QUERY, { name: "getUserbyEmail" }),
    withAuthorization(condition)
  )(withRouter(MySettings))
);

// apiKey: itsAccount.apiKey,
// authDomain: itsAccount.authDomain,
// createdAt: itsAccount.createdAt,
// displayName: itsAccount.displayName,
// emailVerified: itsAccount.emailVerified,
// isAnonymous: itsAccount.isAnonymous,
// lastLoginAt: itsAccount.lastLoginAt,
// phoneNumber: itsAccount.phoneNumber,
// photoURL: itsAccount.photoURL,
// profile: itsProfile,
// payment: itsProfile.payment,
// referral: itsProfile.referral,
// share: itsProfile.share,
// team: itsProfile.team,
// telegram: itsProfile.telegram,
