import PropTypes from "prop-types";
import React, { useState } from "react";
import { BrowserRouter as Route, Link, withRouter } from "react-router-dom";
import { AnimatedSwitch } from "react-router-transition";
import logo from "../../assets/images/xtremex-logo-150.jpg";
import * as ROUTES from "../../constants/routes";
import Forgot from "./forgot";
import SignIn from "./signin";
import SignUp from "./signup";

const Auth = (props) => {
  const [registerClicked, setRegisterClicked] = useState(false);

  Auth.propTypes = {
    match: PropTypes.object.isRequired,
  };

  const registerClickedHandler = (msg) => {
    setRegisterClicked(true);
  };

  let { match } = props;
  let param = match.params.param;

  return (
    <>
      <div className="auth-wrp-outer">
        <div className="auth-wrp-inner">
          <h1 className="is-sr-only">Xtremex</h1>
          <img src={logo} className="auth-logo-title" alt="Xtremex" />
          <div
            className={`auth-body ${
              param === ROUTES.SIGN_UP_NAME ? "register" : "login"
            }`}
          >
            <AnimatedSwitch
              atEnter={{ opacity: 0 }}
              atLeave={{ opacity: 0 }}
              atActive={{ opacity: 1 }}
              className="auth-switch-wrp"
            >
              <Route path={ROUTES.SIGN_IN}>
                <SignIn />
              </Route>
              <Route path={ROUTES.SIGN_UP}>
                <SignUp registerClickedHandler={registerClickedHandler()} />
              </Route>
              <Route path={ROUTES.FORGOT}>
                <Forgot />
              </Route>
            </AnimatedSwitch>
            {param !== ROUTES.FORGOT_NAME && !registerClicked ? (
              <div
                className={`capsule-switch-wrp ${
                  param === ROUTES.SIGN_IN_NAME ? "login" : "register"
                }`}
              >
                <Link
                  className={`capsule-item ${
                    param === ROUTES.SIGN_IN_NAME ? "is-active" : ""
                  }`}
                  to={ROUTES.SIGN_IN}
                >
                  Sign In
                </Link>
                <Link
                  className={`capsule-item ${
                    param === ROUTES.SIGN_UP_NAME ? "is-active" : ""
                  }`}
                  to={ROUTES.SIGN_UP}
                >
                  Sign Up
                </Link>
              </div>
            ) : null}
            {param === ROUTES.SIGN_IN_NAME ? (
              <p className="auth-footer-text">
                <Link className="text-link" to={ROUTES.FORGOT}>
                  Forgot your password?
                </Link>
              </p>
            ) : null}
            {param === ROUTES.FORGOT_NAME ? (
              <p className="auth-footer-text">
                <Link className="text-link" to={ROUTES.SIGN_IN}>
                  Login
                </Link>{" "}
                to your account
              </p>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(Auth);
