import React from "react";
import indexRoutes from "./routes/";
import * as ROUTES from "./constants/routes";
import * as storage from "./storage";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { withAuthentication } from "./session";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { AnimatedSwitch } from "react-router-transition";
import "../src/assets/scss/animationslide.css";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#52c786",
    },
  },
});

const App = () => {
  let authUser = storage.getAuthUser();

  return (
    <ThemeProvider theme={theme}>
      <Router basename="/">
        <AnimatedSwitch
          atEnter={{ opacity: 0 }}
          atLeave={{ opacity: 0 }}
          atActive={{ opacity: 1 }}
          className="switch-wrapper"
        >
          {indexRoutes.map(({ path, Component }, key) => {
            if (path === "/") {
              return (
                <Route exact path={path} key={key} component={Component} />
              );
            } else {
              return <Route path={path} key={key} component={Component} />;
            }
          })}
        </AnimatedSwitch>

        {authUser && authUser.is_setup_account && (
          <Redirect to={ROUTES.DASHBOARD} />
        )}
      </Router>
    </ThemeProvider>
  );
};

export default withAuthentication(App);
