import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import logo from "../../assets/images/xtremex-logo.jpg";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as ROUTES from "../../constants/routes";
import { RESEND_EMAIL, VERIFYING_EMAIL } from "../../graphql";
import { useMutation } from "@apollo/client";
import { Button } from "@material-ui/core";

const authLink = createHttpLink({
  uri: "https://api.xtremex.io/",
  headers: { "content-type": "application/json", "x-hasura-role": "anonymous" },
});

const client = new ApolloClient({
  link: authLink,
  cache: new InMemoryCache().restore(window.__APOLLO_STATE__),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
      errorPolicy: "ignore",
    },
    query: {
      fetchPolicy: "cache-first",
      errorPolicy: "all",
    },
    subscribe: {
      fetchPolicy: "cache-first",
      errorPolicy: "ignore",
    },
  },
});

const queryParams = new URLSearchParams(window.location.search);

const Verify = (props) => {
  const [isEmail] = useState(queryParams.get("email"));
  const [isCode] = useState(queryParams.get("code"));
  const [isStatus, setStatus] = useState("");
  const [isMessage, setMessage] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [resendEmail] = useMutation(RESEND_EMAIL);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    VerifyEmail();
  });

  const VerifyEmail = () => {
    let emailCode = { email: isEmail, verifyCode: isCode };
    client
      .mutate({
        variables: emailCode,
        mutation: VERIFYING_EMAIL,
      })
      .then((result) => {
        if (result.data.verify_code_xtremex.status === true) {
          setStatus(result.data.verify_code_xtremex.status);
          setMessage(result.data.verify_code_xtremex.message);
          setLoading(false);
          redirect_To();
        } else {
          setMessage(result.data.verify_code_xtremex.message);
          setLoading(false);
        }
      })
      .catch((er) => {
        // setLoading(false);
      });
  };

  const ResendEmail = () => {
    let data = { email: isEmail };
    resendEmail({ variables: data })
      .then((result) => {
        setLoading(false);
        enqueueSnackbar(result.data.resend_verification_mail.message, {
          persist: true,
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        });
        setTimeout(() => {
          closeSnackbar();
        }, 2000);
      })
      .catch((err) => {
        enqueueSnackbar(err.data.resend_verification_mail.message, {
          persist: true,
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        });
        setTimeout(() => {
          closeSnackbar();
        }, 2000);
      });
  };

  const redirect_To = () => {
    setTimeout(() => {
      window.location = ROUTES.LOGIN;
    }, 3000);
  };

  return (
    <>
      <div className="auth-wrp-outer">
        <div className="auth-wrp-inner">
          <h1 className="is-sr-only">Xtremex</h1>
          <img src={logo} className="auth-logo-verify" alt="Xtremex" />
          <div className="auth-body single">
            <div className="auth-wrp-verify">
              {isEmail !== "" && isCode !== "" ? (
                <div className="box-verify">
                  {isStatus === true ? (
                    <>
                      <CircularProgress color="primary" />

                      <h2 className="title">Verifying...</h2>
                    </>
                  ) : (
                    <>
                      <FontAwesomeIcon
                        className="icon error"
                        icon={faExclamationCircle}
                      />
                      <Button
                        // disabled={isLoading}
                        className="button btn-block is-primary"
                        type="submit"
                        onClick={ResendEmail}
                        style={{ marginTop: 20, width: "50%", height: "50%" }}
                      >
                        {"RESEND EMAIL"}
                      </Button>
                      <h2 className="title">{isMessage}</h2>
                    </>
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Verify;
