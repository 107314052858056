import { Button, TextField } from "@material-ui/core";
import { withSnackbar } from "notistack";
import React from "react";
import { compose } from "recompose";
import { withRedirectIfAuth } from "../../session";
import { ApolloClient, InMemoryCache } from "@apollo/client";
import * as ROUTES from "../../constants/routes";
import { Link } from "react-router-dom";
import * as storage from "../../storage";
import { LOGIN_AUTH, LOGIN_WITH_TWO_FACTOR } from "../../graphql";
import "../../assets/scss/style2.css";
import logo from "../../assets/images/xtremex-logo-150.jpg";
import PinInput from "react-pin-input";

const client = new ApolloClient({
  uri: "https://api.xtremex.io/",
  cache: new InMemoryCache().restore(window.__APOLLO_STATE__),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
      errorPolicy: "ignore",
    },
    query: {
      fetchPolicy: "cache-first",
      errorPolicy: "all",
    },
    subscribe: {
      fetchPolicy: "cache-first",
      errorPolicy: "ignore",
    },
  },
});
const INITIAL_STATE = {
  email: "",
  password: "",
  code: "",
  error: "",
  errorEmail: "",
  errorPassword: "",
  errorCode: "",
  loading: false,
  twoFacAuth: false,
  datas: [],
};

class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
    this.email = this.email.bind(this);
    this.password = this.password.bind(this);
    this.code = this.code.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.loading = this.loading.bind(this);
  }
  email = (event) => {
    this.setState({ email: event.target.value.toString().trim() }, () => {
      this.validateEmail();
    });
  };

  validateEmail = () => {
    let regxp =
      /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (this.state.email.trim().length < 1) {
      this.setState({
        errorEmail: "Email is required.",
      });
    } else if (!regxp.test(this.state.email.trim())) {
      this.setState({
        errorEmail: "Email format is invalid.",
      });
    } else {
      this.setState({
        errorEmail: "",
      });
      return true;
    }
    return false;
  };

  password = (event) => {
    this.setState({ password: event.target.value.toString().trim() }, () => {
      this.validatePassword();
    });
  };
  code = (value) => {
    this.setState({ code: value.toString().trim() }, () => {
      this.validateCode();
    });
  };

  validatePassword = () => {
    if (this.state.password.trim().length < 1) {
      this.setState({
        errorPassword: "Password is required.",
      });
    } else if (this.state.password.trim().length < 6) {
      this.setState({
        errorPassword: "Password must be at least 6 characters.",
      });
    } else {
      this.setState({
        errorPassword: "",
      });
      return true;
    }
    return false;
  };
  validateCode = () => {
    if (this.state.code.trim().length < 1) {
      this.setState({
        errorCode: "Code is required.",
      });
    } else if (this.state.code.trim().length < 6) {
      this.setState({
        errorCode: "Code must be at least 6 characters.",
      });
    } else {
      this.setState({
        errorCode: "",
      });
      return true;
    }
    return false;
  };
  validate = () => {
    return this.validateEmail() && this.validatePassword();
  };
  validate2 = () => {
    return this.validateCode();
  };
  loading = (status) => {
    this.setState({
      loading: status,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (!this.validate()) {
      return false;
    }
    this.loading(true);
    const { email, password } = this.state;
    var that = this;
    client
      .query({
        variables: { email: email, password: password },
        query: LOGIN_AUTH,
      })
      .then((result) => {
        this.loading(false);
        if (result.data.login_xtremex.status === true) {
          // if login success

          this.props.enqueueSnackbar("Login Success", {
            persist: true,
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          });

          this.setState({ datas: result.data.login_xtremex.data });

          if (result.data.login_xtremex.data.is_setup_account === true) {
            // if account already fill up setup
            if (result.data.login_xtremex.data.is_2fa === true) {
              this.setState({ twoFacAuth: true });
              this.loading(false);
            } else {
              storage.setAuthUser(result.data.login_xtremex.data);
              window.location = ROUTES.DASHBOARD;
            }
          } else if (
            result.data.login_xtremex.data.is_setup_account === false &&
            result.data.login_xtremex.data.is_2fa === true
          ) {
            this.loading(false);
            this.setState({ twoFacAuth: true });
          } else {
            storage.setAuthUser(result.data.login_xtremex.data);
            window.location = ROUTES.DASHBOARD;
            this.loading(false);
          }
        } else {
          // if login failed
          that.setState({
            error: result.data.login_xtremex.message,
          });
          this.loading(false);
          this.props.enqueueSnackbar(result.data.login_xtremex.message, {
            persist: true,
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          });
        }
      })
      .catch((er) => {
        this.loading(false);
      });
  };

  handleSubmitCode = (e) => {
    e.preventDefault();
    if (!this.validate2()) {
      return false;
    }
    this.loading(true);
    const { code, datas } = this.state;
    client
      .query({
        variables: { email: datas.email, key: datas.key_2fa, token: code },
        query: LOGIN_WITH_TWO_FACTOR,
      })
      .then((result) => {
        if (result.data.login_verify_2fa.status === true) {
          localStorage.setItem(
            "data",
            JSON.stringify(result.data.login_verify_2fa.data)
          );
          storage.setAuthUser(result.data.login_verify_2fa.data);
          if (result.data.login_verify_2fa.data.is_setup_account === true) {
            storage.setAuthUser(this.state.datas);
            window.location = ROUTES.DASHBOARD;
            this.props.enqueueSnackbar("Login Success", {
              persist: true,
              variant: "success",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
            });
          } else {
            window.location = ROUTES.SETUP;
            this.props.enqueueSnackbar("Login Success", {
              persist: true,
              variant: "success",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
            });
          }
        } else {
          this.setState({
            error: result.data.login_verify_2fa.message,
          });
          this.loading(false);
          this.props.enqueueSnackbar(result.data.login_verify_2fa.message, {
            persist: true,
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          });
        }
      });
  };

  render() {
    return (
      <div className="login-container-edit">
        <div className="content-wrapper-edit">
          <div className="auth-wrp box-content-edit">
            <div className="box-single">
              <div className={`button-section`}>
                <div className={`capsule-switch-wrp login`}>
                  <Link className={`capsule-item is-active`} to={ROUTES.LOGIN}>
                    Login
                  </Link>
                  <Link className={`capsule-item`} to={ROUTES.REGISTER}>
                    Register
                  </Link>
                </div>
              </div>
              {this.state.twoFacAuth !== true ? (
                <div className={`image-section`}>
                  <img src={logo} className="auth-logo-title" alt="Xtremex" />
                </div>
              ) : (
                false
              )}
              {this.state.twoFacAuth !== true ? (
                <div className={`form-section`}>
                  <form
                    className="auth-form-wrp"
                    onSubmit={this.handleSubmit}
                    noValidate
                    autoComplete="off"
                  >
                    <div className="form-group">
                      <TextField
                        error={this.state.errorEmail ? true : false}
                        id="email"
                        type="email"
                        className="input is-fullwidth"
                        disabled={this.state.loading}
                        value={this.state.email}
                        label="Your email"
                        onChange={this.email}
                        helperText={this.state.errorEmail}
                      />
                    </div>

                    <div className="form-group">
                      <TextField
                        error={this.state.errorPassword ? true : false}
                        id="password"
                        type="password"
                        className="input is-fullwidth"
                        disabled={this.state.loading}
                        value={this.state.password}
                        label="Password"
                        onChange={this.password}
                        helperText={this.state.errorPassword}
                      />
                    </div>

                    <Button
                      disabled={this.state.loading}
                      type="submit"
                      className=" button btn-block is-primary btn-login "
                      style={{
                        marginTop: "20px",
                        width: "100%",
                        backgroundColor: "aqua",
                        color: "black",
                        borderRadius: "20px",
                      }}
                    >
                      {this.state.loading ? "LOGGING IN..." : "LOG IN"}
                    </Button>
                    <Link className="link-forgot-edit" to={ROUTES.FORGOT2}>
                      Forgot your password ?
                    </Link>
                  </form>
                </div>
              ) : (
                false
              )}
              {this.state.twoFacAuth === true ? (
                <div className="text-2fa-edit">2-Step Verification</div>
              ) : (
                false
              )}

              {this.state.twoFacAuth === true ? (
                <div className="desc-text-2fa">
                  Enter the 6 digit code from your authenticator app. This extra
                  step show it's your and protects your and your assets.
                </div>
              ) : (
                false
              )}

              {/* <form
                className="auth-form-wrp"
                onSubmit={this.handleSubmitCode}
                noValidate
                autoComplete="off"
              >
                
              </form> */}
              {this.state.twoFacAuth === true ? (
                <PinInput
                  length={6}
                  initialValue=""
                  onChange={(value) => this.code(value)}
                  type="numeric"
                  inputMode="number"
                  style={{ padding: "10px", marginTop: "20px" }}
                  inputStyle={{
                    borderColor:
                      this.state.errorCode !== "" ? "rgb(255, 0, 255)" : "grey",
                    color: "white",
                    height: "40px",
                    width: "30px",
                    borderRadius: "10px",
                    margin: "5px",
                    backgroundColor: "rgb(59, 59, 59)",
                  }}
                  inputFocusStyle={{
                    borderColor:
                      this.state.errorCode !== "" ? "rgb(255, 0, 255)" : "aqua",
                    borderWidth: "1px",
                  }}
                  autoSelect={true}
                  regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                />
              ) : (
                false
              )}

              {this.state.errorCode !== "" ? (
                <div className="error-code-style">
                  {"( ! ) " + this.state.errorCode}
                </div>
              ) : (
                false
              )}
              {this.state.twoFacAuth === true ? (
                <Button
                  disabled={this.state.loading}
                  onClick={this.handleSubmitCode}
                  className="button btn-block is-primary btn-login"
                  style={{
                    marginTop: "20px",
                    width: "100%",
                    backgroundColor: "aqua",
                    color: "black",
                    borderRadius: "20px",
                  }}
                >
                  {this.state.loading ? "Verifying..." : "Verify"}
                </Button>
              ) : (
                false
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const condition = (authUser) => !!authUser;

export default compose(withSnackbar, withRedirectIfAuth(condition))(SignIn);

// export default SignIn;
