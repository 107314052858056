import React, { useState } from "react";
import logo from "../assets/images/xtremex-logo-150.jpg";
import SignOutButton from "./signout";
import { withAuthorization } from "../session";
import { Link } from "react-router-dom";
import * as ROUTES from "../constants/routes";
import { faChevronDown, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dropdown from "./dropdown";

const TopBar = (props) => {
  const [toggleNavbarClass, setToogleNavbarClass] = useState("");

  const handleToggleNavbar = () => {
    setToogleNavbarClass(toggleNavbarClass ? "" : "is-navbar-active");
  };

  let { match } = props;
  let path = match.path;
  return (
    <>
      <nav className={`sidebar-main-wrp ${toggleNavbarClass}`}>
        <div className={`container-fluid`}>
          <button
            type="button"
            className="btn-burger"
            onTouchStart={handleToggleNavbar}
            onMouseUp={handleToggleNavbar}
          >
            <span className="burger-stripe"></span>
          </button>
          <Link to={ROUTES.DASHBOARD} className="logo-link">
            <img src={logo} className="logo-img" alt="Xtremex" />
          </Link>
          <h1 className="is-sr-only">Xtremex</h1>
          <div className="hidden-header">
            <div className="nav-main">
              <Link
                to={ROUTES.DASHBOARD}
                className={`nav-item ${
                  path === "/dashboard" ? "is-active" : null
                }`}
              >
                <div className="nav-text">Dashboard</div>
              </Link>
              <Link
                to={ROUTES.POOLS}
                className={`nav-item ${path === "/pools" ? "is-active" : null}`}
              >
                <div className="nav-text">Pools</div>
              </Link>
              <Link
                to={ROUTES.TRANSACTION}
                className={`nav-item ${
                  path === "/transaction" ? "is-active" : null
                }`}
              >
                <div className="nav-text">History</div>
              </Link>
            </div>
            <div className="menu-account">
              <div className="account">
                <Dropdown
                  parentClassName="account-name"
                  childrenClassName="account-btn"
                  position="right-down"
                  label={
                    <>
                      {props.authUser.email}{" "}
                      <FontAwesomeIcon className="icon" icon={faChevronDown} />
                    </>
                  }
                  options={[
                    {
                      label: "Settings",
                      onClick: () => props.history.push(ROUTES.MY_SETTINGS),
                    },
                    {
                      label: "Referral",
                      onClick: () => props.history.push(ROUTES.REFERALS),
                    },
                    {
                      label: (
                        <SignOutButton>
                          Log out{" "}
                          <FontAwesomeIcon
                            className="icon"
                            icon={faSignOutAlt}
                          />
                        </SignOutButton>
                      ),
                    },
                  ]}
                />
              </div>
              <div className="notification"></div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(TopBar);
