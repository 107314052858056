import React, { useState, useEffect, useCallback } from "react";
import {
  AreaChart,
  Area,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
} from "recharts";
import { withSnackbar } from "notistack";
import { compose } from "recompose";

import { GET_DATA_CAHRT, REQUEST_WITHDRAWAL, QUERYGETPOOLBALLAST } from "../../graphql/index";
import ETH from "../../assets/images/ethereum-eth-logo.png";
import BTC from "../../assets/images/bitcoin-btc-logo.png";
import BNB from "../../assets/images/binance-logo.png";
import DepositIcon from "../../assets/images/deposit.png";
import WithdrawalIcon from "../../assets/images/withdrawal.png";
import Modal from "react-modal";
import ReactModal from "react-modal";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CSSTransition } from "react-transition-group";
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
} from "@material-ui/core";
import * as storage from "../../storage";
import { useLazyQuery, useQuery, useMutation, useSubscription } from '@apollo/client';
// import {verifyAddress} from '@waves/ts-lib-crypto';

ReactModal.defaultStyles.overlay.backgroundColor = "transparent";
let authUser = storage.getAuthUser() != null ? storage.getAuthUser() : "";

const customStyles = {
  content: {
    top: "60%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    padding: "0px",
  },
};

const Chart_Portofolio = (props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [ChekBox, setChekBox] = useState(true);
  const [isCheckWithdrawAll, setCheckWithdrawAll] = useState(false);
  const [modalWithDrawel, setmodalWithDrawel] = useState(false);
  const [defaultCurrency, setDefaultCurrency] = useState("ETH");
  const [data_chart, set_data_chart] = useState(false);
  const [last_price, set_last_price] = useState(0);
  const { data: get_data_chart } = useQuery(GET_DATA_CAHRT);
  const [isSelectedAll, setSelectionAll] = useState(false);
  const [loading, setLoading] = useState(true);
  const [textErr, setTextErr] = useState('');
  const [amount, setAmount] = useState('0');
  const [editText, setEditText] = useState(true);
  const [errAddressTarget, setErrAddressTarget] = useState("");
  const [addressTarget, setAddressTarget] = useState("");
  const [recipient, setRecipient] = useState("");
  const [errRecipient, setErrRecipient] = useState("");
  const [createWithdrawal] = useMutation(REQUEST_WITHDRAWAL);
  const [Balance, setValueBalance] = useState(0);
  const [isHasBalance, setIsHasBalance] = useState(false);
  const [isUser] = useState(parseInt(localStorage.getItem("userId")));
  const [errValueBalance, setErrValueBalance] = useState("");
  const [getDataBalanceLast, { data: dataBalance, loading: loadingquertbal }] = useLazyQuery(QUERYGETPOOLBALLAST);

  Modal.setAppElement('body');

  useEffect(() => {
    GetList();
    let xUserId = authUser.pools_users.length > 0 ? authUser.pools_users[0].x_user_id : null;
    let variabel = {
      xuserid: xUserId,
    };
    getDataBalanceLast({ variables: variabel });
    console.log(dataBalance);

    if (
      loadingquertbal !== undefined &&
      !loadingquertbal &&
      dataBalance !== undefined &&
      dataBalance.pools_users_balances_lasts !== undefined
    ) {
      if (dataBalance.pools_users_balances_lasts.length > 0) {
        let bal = dataBalance.pools_users_balances_lasts[0].balance;
        if (bal > 0) {
          setValueBalance(bal);
          setIsHasBalance(true);
        } else {
          setValueBalance(bal);
          setIsHasBalance(false);
        }
      } else {
        setIsHasBalance(false);
        setValueBalance(0);
      }
    } else {
      setValueBalance(0);
    }
  }, [get_data_chart, set_data_chart, set_last_price, setValueBalance]);

  const GetList = useCallback(() => {
    if (get_data_chart) {
      if (get_data_chart.pools_balances_this_week) {
        let data = [];
        get_data_chart.pools_balances_this_week.forEach((d) => {
          if (!!d.net_eth) {
            let price = parseFloat(d.net_eth).toFixed(2);
            data.push({
              time: new Date(d.time).toLocaleDateString(),
              net_eth: price,
            });
            set_last_price(price);
          }
        });
        set_data_chart(data);
      }
    }
  }, [get_data_chart, set_data_chart, set_last_price]);

  // const getAddress = useCallback(() => {
  //   let address = authUser.;
  //   console.log(waves);
  //   if (address === null) {
  //     setErrAddressTarget('Getting address......');
  //     return;
  //   } else if (amount > 0 && address !== null) {
  //     setLoading(false);
  //   }
  //   switch (defaultCurrency) {
  //     case 'ETH':
  //       if (address) {
  //         setAddressTarget(address);
  //         setErrAddressTarget('');
  //       } else {
  //         let clone = JSON.parse(JSON.stringify(props.loginData));
  //         clone.wallet_address.waves_address = null;
  //         props.setLoginData(clone);
  //         setAddressTarget('');
  //         setErrAddressTarget(
  //           'Failed to get ETH address, please refresh your browser',
  //         );
  //       }
  //       break;
  //     default:
  //       break;
  //   }
  // }, [props, setAddressTarget, setErrAddressTarget, amount]);

  const onWithdrawalBtnClick = (ev) => {
    // props.enqueueSnackbar("This module is under development", {
    //   persist: true,
    //   variant: "info",
    //   anchorOrigin: {
    //     vertical: "bottom",
    //     horizontal: "center",
    //   },
    // });
    setDefaultCurrency(ev);
    setmodalWithDrawel(true);
  };

  const closeModalWithDrawel = () => {
    setmodalWithDrawel(false);
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const submitWithdraw = () => {
    let accAddress = authUser.pools_users.length > 0 ? authUser.pools_users[0].user_address : null;
    //let poolId = authUser.pools_users.length > 0 ? authUser.pools_users[0].id : null;
    let poolId = 1;
    let xUserId = authUser.pools_users.length > 0 ? authUser.pools_users[0].x_user_id : null;
    let amFloat = parseFloat(amount);
    let variabel = {
      addressOut: recipient === "" ? accAddress : recipient,
      amount: amFloat,
      poolId: poolId,
      xUserId: xUserId,
    };
    console.log(variabel);
    createWithdrawal({ variables: variabel })
      .then(({ data }) => {
        console.log(data);
        if (data.request_withdrawal_xtremex.status === true) {
          props.enqueueSnackbar("Withdraw success", {
            persist: true,
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          });
        } else {
          props.enqueueSnackbar(data.request_withdrawal_xtremex.message, {
            persist: true,
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          });
        }
      })
      .catch((err) => {
        if (err) {
          //setErrAddressTarget(err);
        }
        console.log(err);
      });
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(props.user_address);
    props.enqueueSnackbar("Copy success", {
      persist: true,
      variant: "success",
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
    });
  };

  const onChangedAmount = (change) => {
    let ex = change.target.value;
    console.log("chg amount : " + ex);
    let address =
      authUser.pools_users.length > 0
        ? authUser.pools_users[0].user_address
        : null;
    if (Number(ex) === 0 || address === null) {
      setLoading(true);
      setAmount(ex);
    } else if (isNaN(ex)) {
      setLoading(true);
      setAmount("");
    } else {
      setLoading(false);
      setAmount(ex);
    }
  };

  // BOLEAN CHECK
  const changeCheck = (change) => {
    let address =
      authUser.pools_users.length > 0
        ? authUser.pools_users[0].user_address
        : null;
    if (change.target.checked && address === null) {
      setErrAddressTarget("Portofolio Address not found");
    } else {
      if (change.target.checked) {
        setRecipient(address);
        setErrAddressTarget("");
      } else {
        setRecipient("");
        setErrAddressTarget("");
      }
    }
    setChekBox(change.target.checked);
  };

  const onChangeSelectAll = (change) => {
    setSelectionAll(!isSelectedAll, (currentSelect) => {
      if (currentSelect === true) {
        setLoading(false);
      } else if (textErr !== "" && amount > 0) {
        setEditText(true);
        setLoading(false);
      } else {
        setLoading(true);
      }
    });
    if (isSelectedAll) {
      setAmount(Balance);
    }
  };

  const onChangedAddress = (value) => {
    let addressChg = value.target.value;
    setRecipient(addressChg);
    // let myObjStr = authUser.email;
    // console.log(myObjStr);
    // console.log(authUser.pools_users[0].user_address);
    //const x = verifyAddress(value);
    // if (x) {
    //   setErrRecipient('');
    // } else {
    //   setErrRecipient('Address not found');
    // }
  };

  // console.log("GET_DATA=>", ChekBox);
  return (
    <>
      {/* MODAL DEPOSIT  */}
      <Modal
        closeTimeoutMS={500}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <div className="content-modal-deposite">
          <div className="header-wrap-deposite">
            <div className="title-header-deposite">Deposit</div>
            <div
              onClick={() => setModalIsOpen(false)}
              className="button-close-header"
            >
              X
            </div>
          </div>
          <div className="line-bottom"></div>
          <div className="wrap-button-nav">
            {["ETH"].map((value, key) => {
              if (defaultCurrency == value) {
                return (
                  <div
                    onClick={() => setDefaultCurrency(value)}
                    className="button-modal-true"
                  >
                    {value}
                  </div>
                );
              } else {
                return (
                  <div
                    onClick={() => setDefaultCurrency(value)}
                    className="button-modal-false"
                  >
                    {value}
                  </div>
                );
              }
            })}
          </div>
          <div className="text-content-modal">
            Be sure to select the right cryptocurrency. If you send crypto to
            the wrong address, your crypto will be lost
          </div>
          {defaultCurrency == "ETH" ? (
            <div className="text-content-modal-2">Send Ethereum to</div>
          ) : (
            false
          )}
          {defaultCurrency == "ETH" ? (
            <div className="container-address">
              <div className="content-address">
                <div style={{ color: "black" }}>{props.user_address}</div>
                <FontAwesomeIcon
                  onClick={() => copyToClipboard()}
                  icon={faCopy}
                  className="copy-icon"
                />
              </div>
            </div>
          ) : (
            false
          )}
        </div>
      </Modal>
      {/* MODAL WITHDRAWEL  */}
      <Modal
        closeTimeoutMS={500}
        isOpen={modalWithDrawel}
        onRequestClose={closeModalWithDrawel}
        contentLabel="Withdrawal"
        style={customStyles}
      >
        <div className="content-modal-deposite">
          <div className="header-wrap-deposite">
            <div className="title-header-deposite">Withdrawal</div>
            <div
              onClick={() => setmodalWithDrawel(false)}
              className="button-close-header"
            >
              X
            </div>
          </div>
          <div className="line-bottom"></div>
          {errAddressTarget !== "" ? (
            <div
              style={{
                borderWidth: 1,
                borderStyle: "dotted",
                padding: 20,
                marginBottom: 20,
              }}
            >
              <label>{errAddressTarget}</label>
            </div>
          ) : null}
          <div style={{ paddingLeft: 20, paddingTop: 10 }}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={ChekBox}
                    onChange={changeCheck}
                    name="CheckBox"
                    style={{ color: "blue" }}
                  />
                }
                label="Send To My Portfolio"
              ></FormControlLabel>
            </FormGroup>
          </div>
          {!ChekBox ? (
            <>
              <div className="text-content-modal-2">Recepient</div>
              <div className="container-address">
                <input
                  value={recipient}
                  className="content-address"
                  style={{
                    paddingLeft: "20px",
                    fontSize: "16px",
                    color: "black",
                  }}
                  type="text"
                  id="rectext"
                  label="Recepient"
                  onChange={onChangedAddress}
                  placeholder="Paste Address"
                />
              </div>
            </>
          ) : null}
          {!isSelectedAll ? (
            <>
              <div className="text-content-modal-2">Balance : {!loadingquertbal ? Balance : "loading.. "} Amount : </div>
              <div className="container-address">
                <input
                  className="content-address"
                  style={{ paddingLeft: "20px", fontSize: "16px" }}
                  type="number"
                  id="amoutwithdraw"
                  label="Amount"
                  onChange={onChangedAmount}
                  placeholder="Enter Amount"
                />
              </div>
            </>
          ) : null}
          <div>
            <FormGroup style={{ marginLeft: "20px", marginTop: "10px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isSelectedAll}
                    onChange={onChangeSelectAll}
                    // defaultChecked
                    name="CheckBox"
                    style={{ color: "blue" }}
                  />
                }
                label="Withdrawal All"
              ></FormControlLabel>
            </FormGroup>
          </div>
          <div style={{ padding: 20 }}>
            <Button
              style={{
                backgroundColor: "#0000FF",
                color: "#FFFFFF",
              }}
              variant="contained"
              color="primary"
              onClick={submitWithdraw}
            >
              Withdraw
            </Button>
          </div>
        </div>
      </Modal>
      <div className={`first-wrp`}>
        <div className="box-dashboard-portfolio-edit">Portfolio</div>
        <div className="sub-dashboard-portfolio-edit">
          <div className="count-dashboard-portfolio-edit">{last_price}</div>
          <div className="wrap-dashboard-currency">
            <div className="dashboard-dollar-icon">$</div>
            <div className="block-dashboard-etherium">
              <img src={ETH} alt="" style={{ height: 20, width: 20 }} />
            </div>
          </div>
        </div>
        <div
          className="chart_child_container"
          style={{ backgroundColor: "#242424" }}
        >
          <ResponsiveContainer width="100%" height={262}>
            <AreaChart
              width={300}
              height={250}
              data={data_chart}
              margin={{
                top: 50,
                right: 0,
                left: 0,
                bottom: 0,
              }}
            >
              {/* <Tooltip dataKey="time" fontSize="10px" /> */}
              <XAxis dataKey="time" fontSize="10px" />
              <YAxis fontSize="10px" domain={["auto", "auto"]} />
              <Tooltip />
              <Area
                type="natural"
                dataKey="net_eth"
                stroke="#4d3db2"
                fill="#007cc2"
                radius={20}
                enableBackground={1}
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>
      <div className={`second-wrp`}>
        <div className="box-dashboard-second-wrp">
          <div className="wrp1">
            <div className="sub-wrp1">
              <div className="sub-wrp1-1">
                <img src={ETH} alt="" style={{ height: 15, width: 15 }} />
              </div>
              <div className="coin-label">Etherium ( ETH )</div>
            </div>
            <div className="sub-wrp1-2">{last_price}</div>
          </div>
          <div className="sub-wrp2">
            <button
              onClick={openModal}
              className="btn depobtn deposit"
              title="Deposit"
            >
              <img src={DepositIcon} alt="deposit" />
            </button>
            <button
              onClick={(e) => onWithdrawalBtnClick("ETH")}
              className="btn depobtn withdrawal"
              title="Withdrawal"
            >
              <img src={WithdrawalIcon} alt="withdrawal" />
            </button>
          </div>
        </div>
        <div className="wrp3">
          <div className="b-icon">
            <div className="icon-curr">$</div>
          </div>
          <div className="to-soon">Coming soon ...</div>
        </div>
        <div className="wrp3">
          <div className="b-icon">
            <img src={BTC} alt="" style={{ height: 30, width: 30 }} />
          </div>
          <div className="to-soon">Coming soon ...</div>
        </div>
        <div className="wrp3">
          <div className="b-icon">
            <img src={BNB} alt="" style={{ height: 30, width: 30 }} />
          </div>
          <div className="to-soon">Coming soon ...</div>
        </div>
      </div>
    </>
  );
};

export default compose(withSnackbar)(Chart_Portofolio);
