import Button from "@material-ui/core/Button";
import { SnackbarProvider } from "notistack";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./assets/scss/style.scss";
import store from "./redux/store";
import { ApolloProvider } from "react-apollo";
import { ApolloProvider as ApolloHooksProvider } from "@apollo/react-hooks";
import { ApolloClient } from "apollo-client";
import { HttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { setContext } from "apollo-link-context";
import { WebSocketLink } from "apollo-link-ws";
import { getMainDefinition } from "apollo-utilities";
import { split } from "apollo-link";
import jquery from "jquery";
import App from "./app";
import * as storage from "./storage";

window.$ = window.jQuery = jquery;

const notistackRef = React.createRef();
const onClickDismiss = (key) => () => {
  notistackRef.current.closeSnackbar(key);
};

const httpLink = new HttpLink({ uri: "https://api.xtremex.io/" });

const wsLink = new WebSocketLink({
  uri: `wss://api.xtremex.io/`,
  options: {
    lazy: true,
    reconnect: true,
    connectionParams: async () => {
      const auth = await storage.getAuthUser();
      return {
        headers: {
          Authorization: auth ? `Bearer ${auth.token}` : "",
        },
      };
    },
  },
});

const authLink = setContext((_, { headers }) => {
  const auth = storage.getAuthUser();
  const header = auth
    ? {
        "content-type": "application/json",
        Authorization: `Bearer ${auth.token}`,
      }
    : { "content-type": "application/json", "x-hasura-role": "anonymous" };
  return {
    headers: {
      ...headers,
      ...header,
    },
  };
});

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  authLink.concat(httpLink)
);

const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache().restore(window.__APOLLO_STATE__),
});

ReactDOM.render(
  <Provider store={store}>
    <ApolloProvider client={client}>
      <ApolloHooksProvider client={client}>
        <SnackbarProvider
          ref={notistackRef}
          action={(key) => (
            <Button className="btn-dismiss" onClick={onClickDismiss(key)}>
              Dismiss
            </Button>
          )}
        >
          <App />
        </SnackbarProvider>
      </ApolloHooksProvider>
    </ApolloProvider>
  </Provider>,
  document.getElementById("root")
);
