import React, { useState, useEffect, useCallback } from "react";
import TopBar from "../../components/topbar";
import ETH from "../../assets/images/ethereum-eth-logo.png";
import Arrow from "../../assets/images/call-made-right-arrow.svg";
import { withRouter } from "react-router-dom";
import { withAuthorization } from "../../session";
import { GET_POOLS_BALANCE_WEEK } from "../../graphql";
import { useLazyQuery, useQuery, useSubscription } from "@apollo/client";
import { compose } from "recompose";
import { withSnackbar } from "notistack";
import {
  AreaChart,
  Area,
  Tooltip,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import * as storage from "../../storage";

let authUser = storage.getAuthUser() != null ? storage.getAuthUser() : "";

const pools = (props) => {
  const [isUser] = useState(parseInt(authUser.id));
  const [PoolsWeekly, setPoolsWeekly] = useState([]);
  const [IsStatus, setStatus] = useState("");
  const [PoolsId, setPoolsId] = useState(1);

  const { data: get_data_pools_wekkly, error: error_data_wekkly } = useQuery(
    GET_POOLS_BALANCE_WEEK,
    {
      variables: {
        poolId: PoolsId,
        xUserId: isUser,
      },
    }
  );

  useEffect(() => {
    getData();
  }, [getData]);

  const getData = useCallback(() => {
    if (get_data_pools_wekkly !== undefined && !error_data_wekkly) {
      setPoolsWeekly(get_data_pools_wekkly.get_pool_balance_user_week.data);
      setStatus(get_data_pools_wekkly.get_pool_balance_user_week.status);
    }
  }, [get_data_pools_wekkly, error_data_wekkly]);

  const [datas] = useState(() => [
    {
      pool: { weeks: "Week One", week: "3.67%", year: "567%" },
    },
    {
      pool: { weeks: "Week Two", week: "4.67%", year: "467%" },
    },
    {
      pool: { weeks: "Week Three", week: "2.67%", year: "267%" },
    },
    {
      pool: { weeks: "Week Four", week: "1.67%", year: "667%" },
    },
    {
      pool: { weeks: "Week Five", week: "5.67%", year: "467%" },
    },
    {
      pool: { weeks: "Week Six", week: "3.67%", year: "667%" },
    },
  ]);

  return (
    <>
      <TopBar />
      <main className="app-main">
        <header className="header-app">
          <div className="pools-child">
            <div className="pool-name-percent">My Total Balance:</div>
            <div className="name-pool1">$0,000,000.00</div>
          </div>
          <div className="pools-child-header">
            <div className="Logo">
              <img src={ETH} alt="" className="logo" />
            </div>

            <h5 className="name-pool-title">Ethereum</h5>
            <div className="name-pool2">(ETH)</div>
            <h5 className="name-pool-title2">Pool</h5>
          </div>
          <div className="chart_area">
            {IsStatus == false ? (
              <p className="text-noData">No Pools Data</p>
            ) : (
              <ResponsiveContainer width="100%" height={200}>
                <AreaChart
                  data={PoolsWeekly}
                  margin={{
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 2,
                  }}
                >
                  <linearGradient id="colorPv2" x1="0" y1="0" x2="0" y2="1">
                    <stop
                      offset="10%"
                      stopColor="var(--warning)"
                      stopOpacity={0.7}
                    />
                    <stop
                      offset="90%"
                      stopColor="var(--warning)"
                      stopOpacity={0}
                    />
                  </linearGradient>
                  <Tooltip />
                  <XAxis dataKey="time" />

                  <Area
                    type="natural"
                    dataKey="net_btc"
                    stroke="#463db6"
                    fill="#007cc2"
                    enableBackground
                  />
                  <Area
                    type="natural"
                    dataKey="net_eth"
                    stroke="#463db6"
                    fill="#007cc2"
                    enableBackground
                  />
                  <Area
                    type="natural"
                    dataKey="net_usd"
                    stroke="#463db6"
                    fill="#007cc2"
                    enableBackground
                  />
                </AreaChart>
              </ResponsiveContainer>
            )}
          </div>
          <div className="pools-child">
            <h5 className="name-pool-title1">Weekly Balance History</h5>
          </div>
        </header>
        <div className="detail-main">
          {datas.map((value, index) => {
            return (
              <div className="pool-wrp-detail">
                <div className="pool-detail-main">
                  <div className="pool-space">
                    <div className="Logo-pool">
                      <img src={Arrow} alt="" className="logo" />
                    </div>
                    <div className="name-pool3">{value.pool.weeks}</div>
                    {/* <div className="space-pool"></div> */}
                  </div>
                  <div className="pools-child-detail">
                    <div className="name-pool4">Balances</div>
                    <div>
                      <div className="pool-name-percent1">Starting:</div>
                      <div className="pool-name-percent2">0,000,000.00</div>
                    </div>
                    <div>
                      <div className="pool-name-percent1">Ending:</div>
                      <div className="pool-name-percent2">0,000,000.00</div>
                    </div>
                  </div>
                  <div className="btn-pool">
                    <div className="pool-percent">{value.pool.week}</div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </main>
    </>
  );
};

const condition = (authUser) => !!authUser;

export default withSnackbar(
  compose(withAuthorization(condition))(withRouter(pools))
);
